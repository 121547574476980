import { ConfirmationModalComponent } from './../confirm-modal/confirm-modal.component';
import { CACHE_SUPPORT_SALE_ADDED } from '@models/constants';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/baseComponent';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '@services/user.service';
import { AuthenticationService } from '@services/auth.service';
import { CacheService } from '@services/cache.service';
import { SupportService } from '@services/support.service';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '@services/config.service';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DataLayerService } from '@services/data-layer.service';
import { startCase } from 'lodash';
import { Store } from '@ngxs/store';
import { AddTicketCreationTimer } from './store/actions/limit-ticket-creations.actions';
import { CookieService } from '@services/cookie.service';
import { FirebaseConfigsState } from 'src/app/core/store/state/firebase-configs.state';

import { VoucherState } from 'src/app/core/store/state/voucher.state';
import { UIState } from "../../shared/store/state/ui.state";
import { ButtonClickedTracking } from 'src/app/store/actions/rain-analytics-tracker.actions';
import { RainAnalyticsTrackerClick } from 'src/app/store/interfaces/rain-analytics-tracker';

export interface SalesRequestModel {
    name: string;
    number: string;
    email: string;
    questions: string;
    productName: string;
    platform: string;
    requestedFrom: string;
    subject: string;
}

@Component({
    selector: 'app-sales-modal',
    templateUrl: './sales-modal.component.html',
    styleUrls: ['./sales-modal.component.scss']
})
export class SalesModalComponent extends BaseComponent implements OnInit {
    contactForm: FormGroup;
    siteKey: string;
    loading: boolean;
    sending = false;

    isSignedIn: boolean;
    @Input() productName: string;
    @Input() fromPage: string;
    @Input() isPromo = false;
    @Input() subject: string = '';
    @Input() gmData: string;
    @Input() showSignIn: boolean = true;
    @Input() productHeading = '';
    productHeadingFromRoute = '';
    voucher = this.store.selectSnapshot(VoucherState.getVoucherCode)
    public flags = this.store.selectSnapshot(FirebaseConfigsState.getFlags);
    userMode;

    whatsAppOptIn = true;

    nameValidationMessages: ValidationErrors = {
        required: 'Name is required.',
        pattern: 'Name must contain at least 2 characters.'
    };
    emailValidationMessages: ValidationErrors = {
        required: 'Email is required.',
        pattern: 'Please enter a valid email address.'
    };
    cellNumberValidationMessages: ValidationErrors = {
        required: 'Contact number is required.',
        pattern: 'Please enter a valid contact number.'
    };

    constructor(
        private fb: FormBuilder,
        private configService: ConfigService,
        private toastr: ToastrService,
        private supportService: SupportService,
        private cacheService: CacheService,
        private authService: AuthenticationService,
        private userService: UserService,
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private router: Router,
        private dataLayerService: DataLayerService,
        private store: Store,
        private cookieService: CookieService
    ) {
        super();
    }

    ngOnInit(): void {
        this.userMode = this.store.selectSnapshot(UIState.GetUIMode);
        this.productHeadingFromRoute = this.userMode == 'mobile' ? 'mobile' : 'one';

        let productName: string;

        if (this.userMode && !this.productName) {

            switch (this.userMode) {
                case 'consumer':
                    productName = 'rainone home'
                    break;
                case 'sme':
                    productName = 'rainone work'
                    break;
                case 'mobile':
                    productName = 'rain mobile'
                    break;
                default:
                    productName = ''
                    break;
            }

        }
        else {
            productName = this.productName;
        }
        this.subject = `request a ${productName} call #`;

        this.isSignedIn = this.authService.isSignedIn;
        this.siteKey = this.configService.SITE_KEY;
        this.setForm();
        if (this.authService.isSignedIn) {
            this.getcustomerDetails();
        }

       
    }

    getcustomerDetails() {
        this.userService
            .get()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
                if (res.status === 1) {
                    this.prePopulateForm(res.value);
                }
            });
    }

    setForm() {
        this.contactForm = this.fb.group({
            name: ['', Validators.compose([Validators.required, Validators.pattern(/([0-9A-Za-z\(/\W+)]{2,})/)])],
            number: ['', Validators.compose([Validators.required, Validators.pattern('^(\\+\\d{1,2}\\s?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$|^\\d{10,15}$')])],
            email: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')])],
            questions: ['']
        });
    }

    prePopulateForm(user) {
        this.contactForm.controls.name.setValue(user.firstName + ' ' + user.lastName);
        this.contactForm.controls.number.setValue(user.phone);
        this.contactForm.controls.email.setValue(user.email);
    }

    goTo(link) {
        this.activeModal.close();
        this.router.navigateByUrl(link);
    }

    recaptureBypass() {
        this.formSubmit();
    }

    private formSubmit() {

        const uiMode = this.store.selectSnapshot(UIState.GetUIMode);

        let questions = this.contactForm.value.questions + ' whatsapp acceptance: ' + this.whatsAppOptIn;

        const body: any = {
            message: `<h2> subject: ${this.subject.toLowerCase() || 'rain sales support'}</h2>
      <p style= "font-size: 14px;">(name): ${this.contactForm.value.name}</p>
      <p style= "font-size: 14px;">(tel): ${this.contactForm.value.number}</p>
      <p style= "font-size: 14px;">(email): ${this.contactForm.value.email}</p>
      <p style= "font-size: 14px;">(product): ${this.productName}</p>
      <p style= "font-size: 14px;">(questions): ${questions}</p>
      <p style= "font-size: 14px;">(platform): WEB</p>
      <p style= "font-size: 14px;">(requestedFrom): ${this.fromPage}</p>
      <p style= "font-size: 14px;">(Black friday voucher): ${this.voucher}</p>
      <p style= "font-size: 14px;">(ga_tag): ${this.cookieService.getGATag()}</p>`
        };

        const ticketRequest = {
            name: this.contactForm.value.name,
            address: null,
            email: this.contactForm.value.email,
            subject: this.subject.includes('#')
                ? `${this.subject.toLowerCase() || 'rain sales support'} ${this.contactForm.value.number}`
                : `${this.subject.toLowerCase()  || 'rain sales support'} #${this.contactForm.value.number}`,
            referenceId: this.contactForm.value.number,
            body,
            captcha: null,
            ga_tag: this.cookieService.getGATag(),
        };

        this.supportService
            .addTicket(ticketRequest)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (result: any) => {
              
                    this.loading = false;
                    // this.captchaRef.reset();
                    if (result) {
                        if (!result?.ticket) {
                            setTimeout(() => this.toastr.error('An error occurred while submitting. Please try again', null));
    
                            const rainTrackingClickOj: RainAnalyticsTrackerClick = {
                                buttonId: `form-sales-request-failed-${uiMode}`, 
                                buttonLabel:`form-sales-request-failed-${uiMode}`,
                                event: 'btn-click'
                              }
                          
                            this.store.dispatch(new ButtonClickedTracking(rainTrackingClickOj))
    
                            return;
                        } else {
                            this.loading = false;
                            this.activeModal.close();
                            this.openThankYou();
                            this.redirectToShopPage();
                            this.dataLayerService.requestSalesCall(result?.ticket?.ticket?.id, this.productName);
    
                            const rainTrackingClickOj: RainAnalyticsTrackerClick = {
                                buttonId: `form-sales-request-completed-${uiMode}`, 
                                buttonLabel:`form-sales-request-completed-${uiMode}`,
                                event: 'btn-click'
                              }
                          
                            this.store.dispatch(new ButtonClickedTracking(rainTrackingClickOj))
                        }
                    }
                },
                error: (err) => {

                    const rainTrackingClickOj: RainAnalyticsTrackerClick = {
                        buttonId: `form-sales-request-error-${uiMode}`, 
                        buttonLabel:`form-sales-request-error--${uiMode}`,
                        event: 'btn-click'
                      }
                  
                    this.store.dispatch(new ButtonClickedTracking(rainTrackingClickOj))
                }
            });
    }

    openThankYou() {
        const data = {
            title: 'thank you, <span class="bold">' + startCase(this.contactForm.value.name) + '</span>!',
            description: 'We have received your request. An agent will contact you as soon as they are available.',
            buttonName: 'got it'
        };
        const options = { size: 'md' as any, windowClass: 'slideInUp', centered: true };
        const thankYouModalRef = this.modalService.open(ConfirmationModalComponent, options);
        thankYouModalRef.componentInstance.data = data;
    }

    submit() {
        if (this.cacheService.exists(CACHE_SUPPORT_SALE_ADDED)) {
            this.showAlreadyLoggedTicketModal();
            return;
        } else {
            this.store.dispatch(new AddTicketCreationTimer(12));
            this.formSubmit();
            // this.captchaRef.execute();
        }
    }

    showAlreadyLoggedTicketModal() {
        const options = { size: 'confirm' as any, windowClass: 'slideInUp', centered: true };
        const modalRef = this.modalService.open(ConfirmationModalComponent, options);
        modalRef.componentInstance.data = {
            title: 'heads up',
            description: `You have already logged a ticket`,
            buttonName: 'ok'
        };
    }

    private redirectToShopPage() {
        const url = this.router.url;

        if (url.includes('cart/address')) {
            this.activeModal.dismiss();
            this.router.navigate(['/']);
        }
    }

    public purchaseDisabled() {
        return this.flags.disable_purchase;
    }

    whatsAppOptin() {
        this.whatsAppOptIn = !this.whatsAppOptIn;
    }
}
