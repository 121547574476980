import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { RainAnalyticsTrackerClick } from "../interfaces/rain-analytics-tracker";
import { Injectable } from "@angular/core";
import { ButtonClickedTracking, ButtonClickedTrackingFailed, ButtonClickedTrackingSuccess, SetGClid, SetNavigationID, SetReferrer, SetSource } from "../actions/rain-analytics-tracker.actions";
import { RainAnalyticsTrackerService } from "@services/rain-analytics-tracker.service";
import { tap } from "rxjs/operators";
import { Router } from '@angular/router';
import { CoreState } from "src/app/core/store/state/core.state";
import { AuthState } from "src/app/core/store/state/auth.state";
import { v4 as uuidv4 } from 'uuid';
import { CookieService } from "@services/cookie.service";

export interface RainAnalyticsTrackerModel {
    rainAnalyticsTracker: RainAnalyticsTrackerClick[],
    sessionId: string,
    sessionStartTimestamp: string,
    referrer: string,
    navID: number,
    source: string,
    gClid: string
}
@State<RainAnalyticsTrackerModel>({
    name: 'rainAnalyticsTracker',
    defaults: {
        rainAnalyticsTracker: [],
        sessionId: uuidv4(),
        sessionStartTimestamp: new Date().toISOString(),
        referrer: null,
        navID: 0,
        source: 'web',
        gClid: null
    }

})
@Injectable({
    providedIn: 'root'
})
export class RainAnalyticsTrackerState {
    constructor(
        private store: Store,
        private rainAnalyticsService: RainAnalyticsTrackerService,
        private cookieSvc: CookieService
    ) { 
    }

    @Selector()
    static getGClid(ctx: RainAnalyticsTrackerModel) {
      return ctx.gClid;
    }

    @Action(SetNavigationID)
    SetNavigationID(ctx: StateContext<RainAnalyticsTrackerModel>, action: SetNavigationID) {
        const id = action.payload;

        ctx.patchState({
            navID: id
        });
    }

    @Action(SetSource)
    SetSource(ctx: StateContext<RainAnalyticsTrackerModel>, action: SetSource) {
        const source = action.payload;

        ctx.patchState({
            source: source
        });
    }

    @Action(SetGClid)
    SetGClid(ctx: StateContext<RainAnalyticsTrackerModel>, action: SetGClid) {
        const gClid = action.payload;
        ctx.patchState({
            gClid: gClid
        });
    }

    @Action(ButtonClickedTracking)
    buttonClickedTracking(ctx: StateContext<RainAnalyticsTrackerModel>, action: ButtonClickedTracking) {
        const state = ctx.getState();
        const btnClick = action.payload;
        const userId = this.store.selectSnapshot(AuthState.getDigitalIdentity)?.id ?? 'n/a';
        const resolution = this.store.selectSnapshot(CoreState.activeBreakpoint);
        const userData = navigator['userAgentData'];
        const brand = userData.brands[0];
        
        btnClick.source = state.source,
        btnClick.userId = userId;
        btnClick.timestamp = new Date().toISOString();
        btnClick.pageUrl = window.location.href;
        btnClick.referrerUrl = (document.referrer !== ''  && state.navID < 2)? document.referrer : state.referrer;
        btnClick.deviceInfo = `${userData.platform}, ${brand.brand} ${brand.version}`;
        btnClick.sessionId = state.sessionId;
        btnClick.timeOnPage = 0;
        btnClick.scrollDepth = !btnClick.scrollDepth ? 0 : btnClick.scrollDepth;
        btnClick.clickCoordinates = 'n/a';
        btnClick.source = 'rain.co.za';
        btnClick.sessionStart = state.sessionStartTimestamp;
        btnClick.eventTime = new Date().toISOString();
        btnClick.browserInfo = navigator.userAgent
        btnClick.memoryUsage = `${window.performance['memory']!.usedJSHeapSize / Math.pow(1000, 2)}`;
        btnClick.navigationDepth = state.navID;
        btnClick.gaId = this.cookieSvc.getGATag();

        if(state.gClid) {
            btnClick.gclid = state.gClid;
        }
        let previousEvents = [...state.rainAnalyticsTracker];
        
       return this.rainAnalyticsService.pushButtonClick(btnClick)
        .pipe(
            tap({
                next: (res) => {
                    previousEvents.push(res);
                    ctx.patchState({
                        rainAnalyticsTracker: previousEvents
                    })
                    ctx.dispatch(new ButtonClickedTrackingSuccess());
                   
                },
                error: (err) => {
                    ctx.dispatch(new ButtonClickedTrackingFailed());
                }
            })

        )
    }

    @Action(ButtonClickedTrackingSuccess)
    buttonClickedTrackingSuccess() {

    }

    @Action(ButtonClickedTrackingFailed)
    buttonClickedTrackingFailed() {
    
    }

    @Action(SetReferrer)
    SetReferrer(ctx: StateContext<RainAnalyticsTrackerModel>, action: SetReferrer) {
        const referrer = action.payload;

        ctx.patchState({
            referrer
        })
    }

}