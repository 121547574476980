import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DataLayerService } from '@services/data-layer.service';
import { SSOService } from 'src/app/core/services/sso.service';
import { CoreState } from 'src/app/core/store/state/core.state';

@Component({
  selector: 'rain-sim-for-voice',
  templateUrl: './sim-for-voice.component.html',
  styleUrls: ['./sim-for-voice.component.scss']
})
export class SimForVoiceComponent implements OnInit {
  constructor(private store: Store, private dataLayerService: DataLayerService, private sso: SSOService, private router: Router,) {}

  ngOnInit(): void {}

  openRainGoIfSignedIn() {
    const isAuthed = this.store.selectSnapshot(CoreState.isAuthenticated);

    if (!isAuthed) {
      const url = 'https://www.raingo.co.za'; // add environment urls
      window.location.href = 'https://www.raingo.co.za';
      return;
    }

    this.dataLayerService.rainGoClicked();
    return this.sso.createUrl().then(encodedUrl => {
      window.open(encodedUrl, '_self');
    });
  }

  gotoTerms() {
    this.router.navigateByUrl('/legal?section=rainGo');
  }
}
