import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Result, Statuses} from '@models/result';
import {UserDetail} from '@models/userDetail';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '@services/user.service';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from 'src/app/baseComponent';
import {CACHE_SUPPORT_ADDED} from '@models/constants';
import {SuccessErrorModalComponent} from '../success-error-modal/success-error-modal.component';
import {CacheService} from '@services/cache.service';
import {ConfigService} from '@services/config.service';
import {SupportService} from '@services/support.service';
import {DataLayerService} from '@services/data-layer.service';
import { RainAnalyticsTrackerClick } from 'src/app/store/interfaces/rain-analytics-tracker';
import { ButtonClickedTracking } from 'src/app/store/actions/rain-analytics-tracker.actions';
import { Store } from '@ngxs/store';

@Component({
    selector: 'rain-five-g-cancel-confirm-details-modal',
    templateUrl: './five-g-cancel-confirm-details-modal.component.html',
    styleUrls: ['./five-g-cancel-confirm-details-modal.component.scss']
})
export class FiveGCancelConfirmDetailsModalComponent extends BaseComponent implements OnInit {

    @Output() submitted: EventEmitter<any> = new EventEmitter();
    userForm: FormGroup;
    formSubmit = false;
    userDetails: any;

    nameValidationMessages: ValidationErrors = {
        required: 'First name is required.',
        pattern: 'First name must contain at least 2 characters.'
    };

    emailValidationMessages: ValidationErrors = {
        required: 'Email is required.',
        pattern: 'Please enter a valid email address.',
    };
    cellNumberValidationMessages: ValidationErrors = {
        required: 'Cell number is required.',
        pattern: 'Please enter a valid cell number.'
    };

    detailsSaved: boolean;
    siteKey: string;
    loading = false;

    constructor(
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
        private userService: UserService,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private cacheService: CacheService,
        private configService: ConfigService,
        private dataLayerService: DataLayerService,
        private supportService: SupportService,
        private store: Store
    ) {
        super();
    }

    ngOnInit() {
        this.getUserService();
        this.siteKey = this.configService.SITE_KEY
    }

    getUserService() {
        this.userService
            .get()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result: Result<UserDetail>) => {
                if (result.status != Statuses.Success) {
                    this.toastr.error('An error occurred while processing the request', null);
                    return;
                } else {
                    this.userDetails = result.value;
                    this.userForm = this.fb.group({
                        firstName: this.fb.control(`${this.userDetails?.firstName} ${this.userDetails?.lastName}` || null, Validators.compose([Validators.required, Validators.pattern(/([0-9A-Za-z\(/\W+)]{2,})/)])),
                        phoneNumber: this.fb.control(this.userDetails?.phone || null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$|^[0-9]{12}$')])),
                        email: this.fb.control(this.userDetails?.email || null, Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]))
                    })
                }
            });
    }

    submit() {

        if (this.userForm.invalid) {
            return;
        }

        const rainTrackingClickOj: RainAnalyticsTrackerClick = {
            buttonId: `cancel-service-call-me-success`,
            buttonLabel: `cancel-service-call-me-success`,
            event: 'btn-click'
          }
      
          this.store.dispatch(new ButtonClickedTracking(rainTrackingClickOj))

        if (this.cacheService.exists(CACHE_SUPPORT_ADDED)) {
            this.showAlreadyLoggedTicketModal();
            return;
        } else {
            this.submitTicket();
        }
    }

    showAlreadyLoggedTicketModal() {
        this.activeModal.dismiss();
        const options = {size: 'confirm' as any, windowClass: 'slideInUp', centered: true};
        const modalRef = this.modalService.open(SuccessErrorModalComponent, options);
        modalRef.componentInstance.data = {
            title: 'heads up',
            description: `You have already logged a ticket`,
            buttonName: 'ok'

        };
    }

    successModal() {
        const modalRef = this.modalService.open(SuccessErrorModalComponent, {
            size: 'md' as any,
            centered: true
        });
        modalRef.componentInstance.data = {
            title: 'success!',
            description: 'Your details have been saved and one of our agents will call you soon.',
            buttonName: 'got it'
        };

    }

    submitTicket() {
        this.loading = true;
        const body: any = {
            message: '(name): ' + this.userForm.value.firstName + ' (tel): ' + this.userForm.value.phoneNumber + ' (email): ' + this.userForm.value.email + ' (questions): ' + '5G Cancellation - request network assistance'
        };

        const ticketRequest = {
            name: this.userForm.value.firstName,
            address: null,
            email: this.userForm.value.email,
            subject: `rain cancellation support #${this.userForm.value.phoneNumber}`,
            referenceId: this.userForm.value.phoneNumber,
            body,
            captcha: null
        };

        this.supportService.addTicket(ticketRequest).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result: any) => {
                this.loading = false;
                if (result) {
                    if (!result?.ticket) {
                        setTimeout(() => this.toastr.error('An error occurred while submitting. Please try again', null));
                        return;
                    } else {
                        this.loading = false;
                        this.activeModal.close();
                        this.successModal();
                        this.cacheService.set(CACHE_SUPPORT_ADDED, 'set', 86400000);
                        this.dataLayerService.requestSalesCall(result?.ticket?.ticket?.id, '5G Cancellation');
                    }
                }
            });
    }
}
