import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_PRODUCTS_KEY, CONFIG_SIM_TYPE, SIM_TYPE_VIRTUAL } from '@models/constants';
import { IProductDetail, ProductDetail, ProductTypes } from '@models/productDetail';
import { IRemoteProduct } from '@models/remote/remoteProduct';
import { Result } from '@models/result';
import { Store } from '@ngxs/store';
import { isNil } from 'lodash';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, skipWhile, switchMap, take } from 'rxjs/operators';
import { FirebaseConfigsState } from '../core/store/state/firebase-configs.state';
import { ProductState } from '../store/state/product.state';
import { CacheService } from './cache.service';
import { ConfigService } from './config.service';
import { ServiceHelper } from './serviceHelper';
import * as data from '../../assets/data/rain-one-products.json';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  getByConfig(name: string, value: string, caseSensitive: boolean = true): Observable<Result<IProductDetail[]>> {
    return this.get().pipe(
      map(result => {
        if (caseSensitive) {
          const matchingProducts = result.value.filter(
            product => product.config && product.config[name] && product.config[name].value == value
          );
          return Result.success(matchingProducts);
        } else {
          const matchingProducts = result.value.filter(
            product =>
              product.config && product.config[name] && product.config[name].value.toUpperCase() == value.toUpperCase()
          );
          return Result.success(matchingProducts);
        }
      }),
      catchError(result => ServiceHelper.handleError<IProductDetail[]>(result))
    );
  }

  constructor(private store: Store, private configService: ConfigService, private http: HttpClient, private cacheService: CacheService) { }

  public get(ignoreCache = false): Observable<Result<IProductDetail[]>> {
    const url = `./products.json`
    return this.getproducts(url);
    // return this.store.select(FirebaseConfigsState.productsSourceUrl)
    //   .pipe(skipWhile((url) => !url),
    //     take(1),
    //     switchMap((url) => {
    //       return this.getproducts(url);
    //     })
    //   )
  }
  private getproducts(requestPath) {
   
    if (this.isCached()){
      return of(Result.success(this.getCache()));
    }
    else{
      return this.http.get(requestPath).pipe(
        map((response: any) => {
          const allProducts = this.store.selectSnapshot(ProductState.allProducts);
          const catProducts = this.store.selectSnapshot(ProductState.GetCatelogueProducts);
          const products = [...response.products.map((rawProduct: IRemoteProduct) => ProductDetail.adapt(rawProduct)), ...allProducts, ...catProducts];
          this.setCache(products);
          return Result.success(products);
        })
      );
    }

  }
  public getById(id: string): Observable<Result<any>> {
    return this.get().pipe(
      map(result => {
        const catProducts = this.store.selectSnapshot(ProductState.GetCatelogueProducts);
        // const products = [...result.value, ...catProducts];
        const products = [...result.value, ...catProducts];

        const product = products.filter(x => x.id === id)[0];

        if (isNil(product)) {
          return Result.notFound<IProductDetail>();
        }

        return Result.success(product);
      }),
      catchError(result => ServiceHelper.handleError<IProductDetail>(result))
    );
  }

  public getNonFeatured(): Observable<Result<IProductDetail[]>> {
    return this.getActive().pipe(
      map(result => {
        const productDetails = result.value;
        const nonFeaturedProducts = productDetails.filter(
          product => (isNil(product.featured) || product.featured === false) && product.type != ProductTypes.Addon
        );
        return Result.success(nonFeaturedProducts);
      }),
      catchError(result => ServiceHelper.handleError<IProductDetail[]>(result))
    );
  }

  public getEsimProducts(): Observable<Result<IProductDetail[]>> {
    return this.getByConfig(CONFIG_SIM_TYPE, SIM_TYPE_VIRTUAL);
  }

  public getFeatured(): Observable<Result<IProductDetail[]>> {
    return this.getActive().pipe(
      map(result => {
        const featuredProducts = result.value.filter(product => product.featured === true);

        return Result.success(featuredProducts);
      }),
      catchError(result => ServiceHelper.handleError<IProductDetail[]>(result))
    );
  }

  public getActive(): Observable<Result<IProductDetail[]>> {
    return this.get().pipe(
      map(result => {
        const products = result.value;
        const today = moment();

        const activeProductDetails = products.filter(product => {
          return (
            isNil(product.activeFrom) == false &&
            isNil(product.activeTo) == false &&
            moment(product.activeFrom) <= today &&
            (isNil(product.activeTo) || moment(product.activeTo) >= today)
          );
        });

        return Result.success(activeProductDetails);
      }),
      catchError(result => ServiceHelper.handleError<IProductDetail[]>(result))
    );
  }

  public getShowCallButtons() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apiKey: this.configService.TOGGLE_API_KEY
      })
    };

    const url = this.configService.TOGGLE_URL;

    return this.http.get(url, httpOptions);
  }

  setCache(products: IProductDetail[]) {
    this.cacheService.setObject(CACHE_PRODUCTS_KEY, products, 10000);
  }

  isCached(): boolean {
    return this.cacheService.exists(CACHE_PRODUCTS_KEY);
  }

  getCache(): IProductDetail[] {
    return this.cacheService.getObject<IProductDetail[]>(CACHE_PRODUCTS_KEY);
  }
}
