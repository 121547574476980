import { AgmCoreModule } from '@agm/core';
import { HttpClient, HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG,Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DirectivesModule } from '@directives/directives.modules';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';
import { BaseComponent } from 'src/app/baseComponent';
import { LayoutModule } from 'src/app/layout/layout.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddHeaderInterceptor } from './interceptors/interceptors';
import { PaymentTimeoutModalComponent } from './payment-timeout-modal/payment-timeout-modal.component';
import { PreRicaModalComponent } from './pre-rica-modal/pre-rica-modal.component';
import { RicaDetailsModalComponent } from './rica-details-modal/rica-details-modal.component';
// import { ServiceWorkerModule } from '@angular/service-worker';

// Firebase modules
import { DatePipe } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { CheckboxModule } from '@components/checkbox/checkbox.module';
import { CustomerRatingModalComponent } from '@components/customer-rating-modal/customer-rating-modal.component';
import { SupportButtonModule } from '@components/support-button/support-button.module';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { InboxState } from '@pages/notifications/store/inbox.state';
import { environment } from '../environments/environment';
import { InvalidVoucherModalComponent } from './components/invalid-voucher-modal/invalid-voucher-modal.component';
import { VoucherActivateModalComponent } from './components/voucher-activate-modal/voucher-activate-modal.component';
import { VoucherReedemSuccessComponent } from './components/voucher-reedem-success/voucher-reedem-success.component';
import { CoreModule } from './core/core.module';
import { WebsiteconfigService } from './core/services/websiteconfig.service';
import { BillingState } from './core/store/state/billing.state';
import { CoreState } from './core/store/state/core.state';
import { FirebaseConfigsState } from './core/store/state/firebase-configs.state';
import { RicaState } from './core/store/state/rica.state';
import { VoucherState } from './core/store/state/voucher.state';
import { RainFormsModule } from './shared/components/rain-forms/rain-forms.module';
import { SharedModule } from './shared/shared.module';
import { CartState } from './store/state/cart.state';
import { OrderState } from './store/state/order.state';
import { ProductState } from './store/state/product.state';
import { ServicesState } from './store/state/services.state';
import { TotalDueNowPipe } from './shared/pipes/total-due-now.pipe';
import { RainOneShopComponent } from './pages/rain-one-shop/rain-one-shop.component';
import { AssignSimModule } from '@pages/assign-sim/assign-sim-page.module';
import * as Hammer from 'hammerjs';
import { MapModule } from '@components/map/map.module';
import { SettingUpNetworkComponent } from './components/setting-up-network/setting-up-network.component';
import { OurNetworkModule } from '@components/our-network/our-network.module';
import { AssignAndClaimSimState } from './store/state/assign-claim-sim.state';
import { VerifySimModule } from '@pages/verify-sim-page/verify-sim.module';
import { NumberPortingState } from './store/state/number-porting-state';
import { CopyState } from './store/state/copy.state';
import { SpeedUpActionsComponent } from '@pages/service-page/service-actions/speed-up/speed-up-actions.component';
import { IntlCallingState as IntlCallingState } from './store/state/intl-calling.state';
import { LimitSalesTicketCreationState } from '@components/sales-modal/store/state/limit-ticket-creation.state';
import { NvidiaState } from './store/state/nvidia.state';
import { BlackFridayClaimVoucherModalComponent } from './components/black-friday-claim-voucher-modal/black-friday-claim-voucher-modal.component';
import { WhatsappSendVoucherComponent } from './shared/components/rain-black-friday/whatsapp-send-voucher/whatsapp-send-voucher.component';
import { TMMNState } from './core/store/state/tmmn.state';
import { CoverageState } from './core/store/state/coverage.state';
import { PreCancellationsState } from './store/state/PreCancellations.state';
import { AuthState } from './core/store/state/auth.state';
import { SalesUpgradeModalComponent } from './components/sales-modal/sales-upgrade-modal/sales-upgrade-modal/sales-upgrade-modal.component';
import {CookieService} from 'ngx-cookie-service';
import { RainAnalyticsTrackerState } from './store/state/rain-analytics-tracker.state';

const declarations = [
  BaseComponent,
  AppComponent,
  RicaDetailsModalComponent,
  PaymentTimeoutModalComponent,
  PreRicaModalComponent,
  CustomerRatingModalComponent,
  VoucherActivateModalComponent,
  InvalidVoucherModalComponent,
  VoucherReedemSuccessComponent,
  RainOneShopComponent,
  SettingUpNetworkComponent,  
  SpeedUpActionsComponent, 
  BlackFridayClaimVoucherModalComponent,
  WhatsappSendVoucherComponent,
  SalesUpgradeModalComponent
]

// export class HammerConfig extends HammerGestureConfig {
//   overrides = {
//     swipe: { direction: Hammer.DIRECTION_ALL },
//   };
// }


@NgModule({
  declarations: [
    ...declarations
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    HttpClientJsonpModule,
    LayoutModule,
    VerifySimModule,
    DirectivesModule,
    CoreModule,
    AssignSimModule,
    RainFormsModule,
    CheckboxModule,
    MapModule,
    OurNetworkModule,
    ToastrModule.forRoot(),
    MarkdownModule.forRoot({
      loader: HttpClient, // optional, only if you use [src] attribute
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          breaks: true
        }
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAUKK1ckJNssAk7waDZ370xKh6iPUlylbM',
      libraries: ['places']
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig, 'rain-web'), // Required for everything
    AngularFirestoreModule, // Only required for database features
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    NgxsModule.forRoot([CoreState, InboxState,CartState, FirebaseConfigsState,ServicesState,ProductState, BillingState, OrderState, RicaState,VoucherState,AssignAndClaimSimState,NumberPortingState,CoverageState, AuthState,CopyState, PreCancellationsState,IntlCallingState, LimitSalesTicketCreationState, NvidiaState,TMMNState, RainAnalyticsTrackerState], { developmentMode: !environment.production}),
    NgxsStoragePluginModule.forRoot({
      key: ['product','services','cart', 'core', 'billing', 'NvidiaState', 'PreCancellationsState', 'CoverageState','AuthState','UI']
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    // SupportButtonModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [WebsiteconfigService],
      multi: true
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: setProductsUrl,
    //   deps: [WebsiteconfigService],
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    // {
    //   provide: HAMMER_GESTURE_CONFIG,
    //   useClass: HammerConfig
    // },
    Title,
    DatePipe,
    TotalDueNowPipe,
    //TODO: NgbActiveModal should not be provided here...
    NgbActiveModal,
    CookieService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
export function setProductsUrl(configService: WebsiteconfigService) {
  return () => configService.getProductsUrl();
}
export function initConfig(configService: WebsiteconfigService) {
  return () => configService.getSiteConfig();
}