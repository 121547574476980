import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CACHE_4G_5G_MIGRATION_DETAILS } from '@models/constants';
import { CacheService } from '@services/cache.service';
import { RainAnalyticsTrackerClick } from 'src/app/store/interfaces/rain-analytics-tracker';
import { ButtonClickedTracking } from 'src/app/store/actions/rain-analytics-tracker.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-success-error-modal',
  templateUrl: './success-error-modal.component.html',
  styleUrls: ['./success-error-modal.component.scss']
})
export class SuccessErrorModalComponent {
  @Input()
  title: string;

  @Input()
  serviceId: string;

  @Input()
  description: string;

  @Input()
  btnName: string;

  constructor(
    public activeModal: NgbActiveModal, 
    private router: Router, 
    private cacheService: CacheService,
    private store: Store) {}

  confirm() {
    if (this.serviceId) {

      const rainTrackingClickOj: RainAnalyticsTrackerClick = {
        buttonId: `service-page-speed-up-modal-confirmed`, 
        buttonLabel: `service-page-speed-up-modal-confirmed`, 
        event: 'btn-click'
      }
  
      this.store.dispatch(new ButtonClickedTracking(rainTrackingClickOj))

      this.router.navigateByUrl(`/service/${this.serviceId}`);
      this.cacheService.remove(CACHE_4G_5G_MIGRATION_DETAILS);
    }
    this.activeModal.close();
  }
}
