<div class="mb-4 modal-header border-0 ">
  <div class="modal-img-close-btn z-index-5">
    <img alt="Close" aria-label="Close" class="pointer" (click)="cancel()" src="../../assets/svg/close.svg" />
  </div>
</div>
<div class="modal-body border-0 px-25">
  <div class="p-2" *ngIf="data?.title">
    <h2>
      <span class="text-primary" [innerHTML]="data?.title"></span>
    </h2>
  </div>
  <div class="description" [ngClass]="{'p-2': data?.description !== 'Your details have been saved.'}" *ngIf="data?.description"
    [innerHtml]="data?.description"></div>
  <div class="p-2 description" *ngIf="data?.description2" [innerHtml]="data?.description2"></div>
</div>

<div class="px-25 mb-10 actions-footer" *ngIf="data?.buttonName || data?.cancelButtonName">
  <button *ngIf="data?.cancelButtonName" class="btn btn-sm btn-outline-primary mx-5" (click)="cancel()">{{
    data?.cancelButtonName }}</button>
  <button [attr.data-text]="gmData" data-id="btn-click" *ngIf="data?.buttonName" class="btn btn-md btn-primary mx-5"
    (click)="confirm()">{{ data?.buttonName }}</button>
  <app-support-button *ngIf="data?.showSupport"></app-support-button>
</div>