<!-- Modal -->

<div class="modal-white-border-radius bg-white">
<div>
    <div class="bg-primary modal-header pr-0 modal-blue-border-radius pl-md-40 pl-10">
      <div class="mt-15 welcome-heading">
        <h2 *ngIf="!isSignedIn" class="text-white font-weight-bold">
          welcome <br />
          to rain
        </h2>
        <h2 *ngIf="isSignedIn" class="text-white font-weight-bold">
          hi there, <br />
          {{ displayName }}
        </h2>

        <div class="mb-10">
          <button
            *ngIf="!isSignedIn"
            data-id="btn-click"
            data-text="side nav | sigin in"
            class="btn c-btn"
            (click)="signIn()"
          >
            sign in
          </button>

          <button
            *ngIf="isSignedIn"
            data-id="btn-click"
            data-text="side nav | sigin out"
            class="btn c-btn"
            (click)="signOut()"
          >
            sign out
          </button>
        </div>
      </div>
      <div class="close m-0 p-0 pt-3 pr-10" data-dismiss="modal" aria-label="Close" (click)="onCloseModal()">
        <img src="/assets/images/xmark.svg" id="closeButton" class="cursor" alt="Close" />
      </div>
    </div>

    <div class="bg-white modal-body pl-sm-40 pl-10">
      <ul class="list-group pl-0" (click)="onCloseModal()">
        <li class="list-group-item pl-0 border-0 bg-transparent active">
          <a
            class="gey-text"
            data-id="nav-click"
            data-text="shop"
            (click)="home(); activeModal.dismiss('shop'); scrollToShop()"
            >shop</a
          >
        </li>

        <li class="list-group-item pl-0 border-0 bg-transparent" *ngIf="isSignedIn">
          <a
            class="gey-text"
            data-id="nav-click"
            data-text="my rain"
            (click)="activeModal.dismiss('my rain')"
            [routerLink]="['/my-rain']"
            >my rain</a
          >
        </li>
        
        <li class="list-group-item pl-0 border-0 bg-transparent" *ngIf="!isSignedIn">
          <a
            class="gey-text"
            data-id="nav-click"
            data-text="about my rain"
            (click)="activeModal.dismiss('about my rain')"
            [routerLink]="['/about-my-rain']"
            >my rain</a
          >
        </li>
        <!-- <li class="list-group-item pl-0 border-0 bg-transparent" *ngIf="isSignedIn">
          <a class=" text-white" (click)="activeModal.dismiss('scrollToShop'); scrollToShop()">rain store</a>
        </li> -->
        <li class="list-group-item pl-0 border-0 bg-transparent" *ngIf="isSignedIn">
          <a
            class="gey-text"
            data-id="nav-click"
            data-text="my orders"
            (click)="activeModal.dismiss('orders')"
            [routerLink]="['/orders']"
            >my orders</a
          >
        </li>

        <li class="list-group-item pl-0 border-0 bg-transparent" *ngIf="isSignedIn && displayPreferences">
          <span>
            <a
              class="gey-text"
              data-id="nav-click"
              data-text="notification preference"
              (click)="activeModal.dismiss('notification preference')"
              [routerLink]="['/notifications']"
              >my notifications
            </a>
          </span>
          <span class="badge">
            <span class="circle text-white">{{ totalUreadMessages$ | async }}</span>
          </span>
        </li>
        <li class="list-group-item pl-0 border-0 bg-transparent active">
          <a
            class="gey-text"
            data-id="nav-click"
            data-text="setting-up-network"
            (click)="activeModal.dismiss('setting-up-network')"
            [routerLink]="['/4Gmobile']"
            >4G mobile</a>
        </li>
        <li class="list-group-item pl-0 border-0 bg-transparent active">
          <a
            class="gey-text"
            data-id="nav-click"
            data-text="support"
            (click)="activeModal.dismiss('support')"
            [routerLink]="['/support']"
            >support</a
          >
        </li>
        <li class="list-group-item pl-0 border-0 bg-transparent active">
          <a
            class="gey-text"
            data-id="nav-click"
            data-text="legal"
            (click)="activeModal.dismiss('legal')"
            [routerLink]="['/legal']"
            >legal</a
          >
        </li>

        <li class="list-group-item pl-0 border-0 bg-transparent active" >
          <a
            class="gey-text"
            data-id="nav-click"
            data-text="legal"
            (click)="activeModal.dismiss('nvidia')"
            [routerLink]="['/nvidia']"
            > <img class="nvidia-img" src="assets/images/nvidia/desktop-logo.svg" /></a
          >
        </li>
      </ul>

      <!-- <div class="border-bottom border-top">
        <div class="app-wrapper-mobi card-block pb-5 pt-5" *ngIf="isMobileScreen">
          <img
            alt="app gallery"
            data-id="exit-click"
            (click)="goToExternalUrl('https://appgallery.huawei.com/#/app/C103109563')"
            data-text="app gallery | https://appgallery.huawei.com/#/app/C103109563"
            src="/assets/images/huawei.png"
            class="img-fluid mr-7 cursor"
            width="55px"
            height="60px"
          />

          <img
            alt="google store"
            data-id="exit-click"
            (click)="goToExternalUrl('https://play.google.com/store/apps/details?id=consumer.app.co.za.rain&gl=ZA')"
            data-text="google store | https://play.google.com/store/apps/details?id=consumer.app.co.za.rain&gl=ZA"
            src="/assets/images/google-play-img.png"
            class="img-fluid mx-auto cursor"
            width="55px"
            height="60px"
          />

          <img
            alt="apple store"
            data-id="exit-click"
            (click)="goToExternalUrl('https://play.google.com/store/apps/details?id=consumer.app.co.za.rain&gl=ZA')"
            data-text="apple store | https://play.google.com/store/apps/details?id=consumer.app.co.za.rain&gl=ZA"
            src="/assets/images/apple-store-mobi.svg"
            class="img-fluid mx-auto cursor"
            width="55px"
            height="60px"
          />
        </div>

        <div class="pb-5 pt-5" *ngIf="!isMobileScreen">
          <div>
            <span class="gey-text">download the <strong>my rain app</strong></span>
          </div>
          <div class="app-wrapper d-md-flex flex-wrap">
            <div>
              <img
                data-id="exit-click"
                data-text="google store | https://play.google.com/store/apps/details?id=consumer.app.co.za.rain&gl=ZA"
                class="py-2 app-store-img"
                src="/assets/images/download-app/google-play-img.png"
                alt=""
                (click)="goToExternalUrl('https://play.google.com/store/apps/details?id=consumer.app.co.za.rain&gl=ZA')"
              />
            </div>

            <div class="mr-md-6">
              <img
                data-id="exit-click"
                data-text="app gallery | https://appgallery.huawei.com/#/app/C103109563"
                class="py-2 app-store-img"
                src="/assets/images/download-app/huawei.png"
                alt=""
                (click)="goToExternalUrl('https://appgallery.huawei.com/#/app/C103109563')"
              />
            </div>
            <div class="mr-md-6">
              <img
                data-id="exit-click"
                data-text="apple store | https://apps.apple.com/za/app/my-rain-app/id1642478404"
                class="py-2 app-store-img"
                src="/assets/images/apple-store-desktop.svg"
                alt=""
                (click)="goToExternalUrl('https://apps.apple.com/za/app/my-rain-app/id1642478404')"
              />
            </div>
          </div>
        </div>
      </div> -->

    
    </div>
  </div>
</div>
