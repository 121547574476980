
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Store } from '@ngxs/store';
import { ConfigService } from '@services/config.service';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { ConfigStoreService } from 'src/app/store/config.store.service';
import { BillCycleOption } from '../interfaces/bill-cycle-option.interface';
import { SetProductsSourceUrl } from "../store/actions/firebase.actions";

import { environment } from 'src/environments/environment';
import { LoadsheddingFallbackCopy } from '../interfaces/loadshedding-fallbacl-copy.interface';
import { ModalCopy } from '../interfaces/modal-copy';
import { PickupPoint } from '../interfaces/pickup-locations.interface';
import { RainFlags } from '../interfaces/rain-flags.interface';
import { T2025thOfMonthDetails, T204GVerificationDetails, T20CongratsModalDetails, T20HeadsUpModalDetails, 
         T20HomepageDetails, T20Payment5GDetails, T20ProductpageDetails } from '../interfaces/t20-promo.interface';

export interface T20PromoDetails {
  fiveG: {
    button: string,
    primaryText: string,
    secondaryText: string,
  },
  fourG: {
    button: string,
    list: string[],
    primaryText: string,
    secondaryText: string,
  },
  primaryBanner: {
    primaryText: string,
    secondaryText: string
  },
  secondaryBanner: {
    primaryText: string
  }
  tsAndcs: string
  congratsModal: {
    isNotCurrentCustomer: string,
    isCurrentCustomer: string,
    button: string
  }

}

export interface CourierOptions {
  collection: number;
  warehouse: number;
}
export interface WebConfig {
  payDateTransition: boolean;
  showPayDate: boolean;
  rainCourier: CourierOptions;
  rainMakerVersion: string;
  bill_cycle_dates: Array<BillCycleOption>;
  marque_banner?: {
    message?: string,
    show?: boolean
  }

}
@Injectable({
  providedIn: 'root'
})
export class WebsiteconfigService {
  baseUrl: string;
  config: WebConfig;

  constructor(
    private firestore: AngularFirestore,
    private afStorage: AngularFireStorage,
    private confStore: ConfigStoreService,
    private configs: ConfigService,
    private store: Store
  ) {
    if (location.origin.includes('rain.co.za')) {
      this.baseUrl = 'website-config';
    } else {
      this.baseUrl = 'website-config-dev';
    }

  }

  listenToGlobalNotificationSettings() {
    this.fetchBillCycleOptions();
    return this.firestore
      .doc(this.baseUrl + '/notification-settings')
      .snapshotChanges()
      .pipe(map((actions) => {
        const data = actions.payload.data();
        return data;
      }));
  }

  listenToGlobalSignout() {
    return this.firestore
      .doc(this.baseUrl + '/global-signout')
      .snapshotChanges()
      .pipe(map((actions) => {
        const data = actions.payload.data();
        return data;
      }));
  }

  setGlobalConfigs() {
    return new Promise((resolve, reject) => {
      this.firestore
        .doc(this.baseUrl + '/user-access')
        .snapshotChanges()
        .pipe(map((actions) => {
          const data = actions.payload.data() as WebConfig;
          this.confStore.config = data;
          return data;
        })).subscribe((data) => {
          resolve(true);
        }, error => {
          reject();
        });
    });
  }

  getGlobalNotifications() {
    return this.firestore
      .doc(this.baseUrl + '/global-notification')
      .snapshotChanges()
      .pipe(map((actions) => {
        const data = actions.payload.data();
        return data;
      }));
  }


  getDeliveryDelay() {
    return this.firestore
      .doc(this.baseUrl + '/delivery-wait-time')
      .snapshotChanges()
      .pipe(map((actions) => {
        return actions.payload.data();
      }));
  }

  getMyRainNotifications() {
    return this.firestore
      .doc(this.baseUrl + '/my-rain-notification')
      .snapshotChanges()
      .pipe(map((actions) => {
        const data = actions.payload.data();
        return data;
      }));
  }

  getEmergencyComms<T>(): Observable<T> {
    return this.firestore
      .doc('rain-config/emergency-message')
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data = actions.payload.data();
            return data as T;
          }
        )
      );
  }

  checkOrderRegion(): Observable<{ pickupPoints: PickupPoint[] }> {
    return this.firestore
      .doc(this.baseUrl + '/pickup-points')
      .snapshotChanges()
      .pipe(
        map(
          (resp) => {
            const data = resp.payload.data();
            return data as { pickupPoints: PickupPoint[] };
          }
        )
      );
  }

  getSiteConfig() {
    // return this.afStorage
    //   .ref(`/website-configs/config_${environment.config}.json`)
    //   .getDownloadURL()
    //   .pipe(tap((fileUrl) => {
    //     this.configs.load(fileUrl);

    //   }))

    const url = `./config.json`
    return  this.configs.load(url);

  }

  getProductsUrl() {
    // return this.afStorage
    //   .ref(`/website-configs/products_${environment.config}.json`)
    //   .getDownloadURL()
    //   .pipe(tap((fileUrl) => {
    //     this.store.dispatch(new SetProductsSourceUrl(fileUrl))
    //   }))

    const url = `./products.json`;
    this.store.dispatch(new SetProductsSourceUrl(url))
  }

  fetchBillCycleOptions() {
    return this.firestore
      .doc(`${this.baseUrl}/bill_cycle_dates`)
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data: any = actions.payload.data();
            const options = JSON.parse(data.bill_cycle_dates);
            return options;
          }
        )
      );
  }

  fetchR1PromoConfig() {
    return this.firestore
      .doc(`${this.baseUrl}/r1_special`)
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data: any = actions.payload.data();
            return data;
          }
        )
      );
  }

  fetchBlackFridayPromoConfig() {
    return this.firestore
      .doc(`${this.baseUrl}/black-friday-promo`)
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data: any = actions.payload.data();
            return data;
          }
        )
      );
  }

  fecthDeliveryWaitTime() {
    return this.firestore
      .doc(`${this.baseUrl}/delivery-wait-time`)
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data: any = actions.payload.data();
            const options = data.days;

            return options;
          }
        )
      );
  }

  fetchPickupLocations() {
    return this.firestore
      .doc(`${this.baseUrl}/pickup-points`)
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data: any = actions.payload.data();
            return data.pickupPoints;
          }
        )
      );
  }

  fetchNvidiaActive() {
    return this.firestore
      .doc(`${this.baseUrl}/nvidia-active`)
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data: any = actions.payload.data();
            return data.active;
          }
        )
      );
  }

  fetchTechnicalIssuesComms() {
    return this.firestore
      .doc(`${this.baseUrl}/technical-issues`)
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data: any = actions.payload.data();
            return data.issues;
          }
        )
      );
  }

  fetchLoadSheddingFallbackCopy(): Observable<LoadsheddingFallbackCopy> {

    return this.firestore
      .doc(`${this.baseUrl}/loadshedding-fallback-copy`)
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data: any = actions.payload.data();
            return data;
          }
        )
      );
  }

  fetchRainFlags(): Observable<RainFlags> {

    return this.firestore
      .doc(`${this.baseUrl}/flags`)
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data: any = actions.payload.data();
            return data;
          }
        )
      );
  }

  fetchGiveAGigConfig(): Observable<ModalCopy> {

    return this.firestore
      .doc(`${this.baseUrl}/give-a-gig-promo`).snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data: any = actions.payload.data();
            return data;
          }
        )
      )
  }
  
  fecthRICAStatusCopy() {
    return this.firestore
      .doc(`${this.baseUrl}/rica`)
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data: any = actions.payload.data();
            return data.statusCopy;
          }
        )
      );
  }


  fetchT20Productpage(): Observable<T20ProductpageDetails> {
    return this.firestore
      .doc(`${this.baseUrl}/t20/product-page/copy`)
      .snapshotChanges()
      .pipe(
        map(
          (res) => {
            return  res.payload.data() as T20ProductpageDetails
          }
        )
      )
  }

  fetchT20Homepage(): Observable<T20HomepageDetails> {
    return this.firestore
      .doc(`${this.baseUrl}/t20/homepage-default/copy`)
      .snapshotChanges()
      .pipe(
        map(
          (res) => {
            return  res.payload.data() as T20HomepageDetails
          }
        )
      )
  }

  fetchT2025thOfMonthpage(): Observable<T2025thOfMonthDetails> {
    return this.firestore
      .doc(`${this.baseUrl}/t20/5G-promo-pages/25th-of-the-month`)
      .snapshotChanges()
      .pipe(
        map(
          (res) => {
            return  res.payload.data() as T2025thOfMonthDetails
          }
        )
      )
  }

  fetchT20Payment5Gpage(): Observable<T20Payment5GDetails> {
    return this.firestore
      .doc(`${this.baseUrl}/t20/5G-promo-pages/payment-5G-basic`)
      .snapshotChanges()
      .pipe(
        map(
          (res) => {
            return  res.payload.data() as T20Payment5GDetails
          }
        )
      )
  }

  fetchT20Congratsmodal(): Observable<T20CongratsModalDetails> {
    return this.firestore
      .doc(`${this.baseUrl}/t20/4G-promo-pages/congrats-modal`)
      .snapshotChanges()
      .pipe(
        map(
          (res) => {
            return  res.payload.data() as T20CongratsModalDetails
          }
        )
      )
  }

  fetchT20HeadsUpmodal(): Observable<T20HeadsUpModalDetails> {
    return this.firestore
      .doc(`${this.baseUrl}/t20/4G-promo-pages/heads-up-modal`)
      .snapshotChanges()
      .pipe(
        map(
          (res) => {
            return  res.payload.data() as T20HeadsUpModalDetails
          }
        )
      )
  }

  fetchT204GVerificationpage(): Observable<T204GVerificationDetails> {
    return this.firestore
      .doc(`${this.baseUrl}/t20/4G-promo-pages/4G-sim-verification`)
      .snapshotChanges()
      .pipe(
        map(
          (res) => {
            return  res.payload.data() as T204GVerificationDetails
          }
        )
      )
  }

  fetchRainOneActions(): Observable<any> {
    return this.firestore
      .doc(`${this.baseUrl}/rain-one`)
      .snapshotChanges()
      .pipe(
        map(
          (res) => {
            return  res.payload.data()
          }
        )
      )
  }
  fetchGeforceMemberships(): Observable<any> { //TODO: FIX ANY
    return this.firestore
      .doc(`${this.baseUrl}/GeForceMemberships`)
      .snapshotChanges()
      .pipe(
        map(
          (res) => {
            return  res.payload.data()
          }
        )
      )
  }

  fetchTopUpPrices(): Observable<any> {
    const isSit = !window.location.href.includes('rain.co.za')
    const url = `rain-config${isSit? '-debug': ''}/top-up`
    return this.firestore
      .doc(url)
      .snapshotChanges()
      .pipe(
        map(
          (res) => {
            return  res.payload.data()
          }
        )
      )
  }

  fetchPromos() {
    return this.firestore
      .doc(`${this.baseUrl}/promos`)
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data: any = actions.payload.data();
            return data;
          }
        )
      );
  }
  fetchDefaultSkin() {
    return this.firestore
      .doc(`${this.baseUrl}/default-skin`)
      .snapshotChanges()
      .pipe(
        map(
          (actions) => {
            const data: any = actions.payload.data();
            return data;
          }
        )
      );
  }
}


