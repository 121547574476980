import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { isEmpty, isNil } from 'lodash';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../baseComponent';
import { MAP_SEARCH_CONFIG } from '@models/constants';
import { CoverageNotifyRequest } from '@models/coverageNotifyRequest';
import { Statuses } from '@models/result';
import { UserService } from '@services/user.service';
import { DataLayerService } from '@services/data-layer.service';
import { FourgUpsellComponent } from '../fourg-upsell/fourg-upsell.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '@services/product.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmNotifyMeModalComponent } from '@pages/service-page/service-actions/confirm-notify-me-modal/confirm-notify-me-modal.component';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { CoverageState } from 'src/app/core/store/state/coverage.state';
import { AuthenticationService } from '@services/auth.service';
import { ConfigService } from '@services/config.service';
import { ConfirmationModalComponent } from '@components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-fiveg-notify',
  templateUrl: './fiveg-notify.component.html',
  styleUrls: ['./fiveg-notify.component.scss']
})
export class FivegNotifyComponent extends BaseComponent implements OnInit {

  isSignedIn: boolean;
  siteKey: string;

  @Output() onSubmit = new EventEmitter<{ status: boolean; name: string }>();

  @Input()
  latitude: number;

  @Input()
  longitude: number;

  @Input()
  notify5G: boolean;

  @Input()
  notify4G: boolean;

  @Input()
  product: any;

  @Input()
  address: string = null;

  @Input()
  isCpt = false;

  @Input()
  showAddress = true;

  @Input()
  type: string;

  registerForm: FormGroup;

  public name: string;

  public submitted = false;

  public config = MAP_SEARCH_CONFIG;
  loading: boolean;
  lastAddress: any;

  nameValidationMessages: ValidationErrors = {
    required: 'First name is required.',
    pattern: 'First name must contain at least 2 characters.'
  };
  lastNameValidationMessages: ValidationErrors = {
    required: 'Last name is required.',
    pattern: 'Last name must contain at least 2 characters.'
  };
  emailValidationMessages: ValidationErrors = {
    required: 'e-mail is required.',
    pattern: 'email is invalid.'
  };
  cellNumberValidationMessages: ValidationErrors = {
    required: 'Cell number is required.',
    pattern: 'Please enter a valid cell number.'
  };

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private productService: ProductService,
    private dataLayerService: DataLayerService,
    private store: Store,
    private configService: ConfigService,
    private authService: AuthenticationService
  ) {
    super();
  }

  ngOnInit() {
    this.isSignedIn = this.authService.isSignedIn;
    this.siteKey = this.configService.SITE_KEY;
    if (this.authService.isSignedIn) {
      this.getCustomerDetails();
    }
    const controlConfig: any = {
      firstName: ['', Validators.compose([Validators.required, Validators.pattern(/([0-9A-Za-z\(/\W+)]{2,})/)])],
      lastName: ['', Validators.compose([Validators.required, Validators.pattern(/([0-9A-Za-z\(/\W+)]{2,})/)])],
      email: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')])],
      tel: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$|^[0-9]{12}$')])]
    };

    if (this.showAddress) {
      controlConfig.address = ['', [this.verifyAddress]];
    }

    this.registerForm = this.formBuilder.group(controlConfig);
  }

  getCustomerDetails() {
    this.userService
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res.status === 1) {
          this.prePopulateForm(res.value);
        }
      });
  }

  prePopulateForm(user) {
    this.registerForm.controls.firstName.setValue(user.firstName);
    this.registerForm.controls.lastName.setValue(user.lastName);
    this.registerForm.controls.email.setValue(user.email);
    this.registerForm.controls.tel.setValue(user.phone);
  }
  
  verifyAddress = (control: AbstractControl): { [key: string]: boolean } | null => {
    const formAddress = control.value;

    if (isNil(this.address) || isEmpty(this.address)) {
      return { verify: true };
    }

    if (this.address !== formAddress) {
      this.address = null;
      return { verify: true };
    }

    return null;
  };

  get f() {
    return this.registerForm.controls;
  }

  public handleMapSearch(result: Address) {
    this.address = result.formatted_address;

    this.latitude = result.geometry.location.lat();

    this.longitude = result.geometry.location.lng();

    this.lastAddress = result.formatted_address;
  }

  public handleSubmit(type: string) {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;
    this.name = this.registerForm.get('firstName').value;

    let notificationtType = type;

    if (!type || this.type === '4G cancellation') {
      notificationtType = '5G';
    }
    const address = this.store.selectSnapshot(CoverageState.getUserAddress);
    const fullAddress = `${address.streetNumber} ${address.streetName}, ${address.suburb}, ${address.city}, ${address.postalCode}`;

    const request: CoverageNotifyRequest = {
      type: notificationtType,
      firstName: this.name,
      lastName: this.registerForm.get('lastName').value,
      email: this.registerForm.get('email').value,
      telephone: this.registerForm.get('tel').value,
      address: fullAddress,
      latitude: this.latitude,
      longitude: this.longitude,
      isCpt: this.isCpt
    };

    this.userService
      .coverageNotify(request)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        result => {
          let value = false;
          this.dataLayerService.notifyCoverageForm();
          if (result.status === Statuses.Conflict || result.status === Statuses.Success) {
            value = true;
          } else {
            value = false;
          }
          this.resetForm();
          this.loading = false;

          if (!type && this.type !== '4G cancellation') {
            this.show4GProduct();
          } else if (this.type === '4G cancellation') {
            this.confirmModal();
          }
          //this.onSubmit.emit({ status: value, name: this.name });
        },
        e => {
          this.toastr.error('An error occurred while processing the request', null);
          this.loading = false;
        }
      );
  }

  show4GProduct(): void {
    if (window.location.href.includes('register/cart')) {
      this.store.dispatch(new Navigate(['/']));
      this.activeModal.dismiss();
    }
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'md' as any,
      centered: true
    });
    modalRef.componentInstance.data = {
      title: `Thanks ${this.name}`,
      description: `<p class="pt-sm-10 pl-sm-20 pr-sm-20">We have got your details. We will let you know as soon as there is coverage in your area.</p> `,
      buttonName: 'got it'
    };
  }

  confirmModal(): void {
    this.activeModal.dismiss();

    const address = {
      formattedAddress: this.address,
      lat: this.latitude,
      lon: this.longitude
    };

    ConfirmNotifyMeModalComponent.show(this.modalService, address);
  }

  resetForm() {
    if (this.showAddress) {
      this.address = null;
      this.latitude = null;
      this.longitude = null;
    }

    this.submitted = false;

    this.registerForm.reset();
  }
}
