import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreloadingStrategyService } from 'src/app/preloadstrategy';
import { LoadBillCycleDatesResolver } from './core/resolvers/load-bill-cycle-fates.resolver';
import { AuthenticatedGuard, GuestGuard, SaIDGuard } from './guards/guards';
import { SettingUpNetworkComponent } from '@components/setting-up-network/setting-up-network.component';
import { FetchCatelogueResolver } from './core/resolvers/fetch-catelogue.resolver';
import { NvidiaWrapperComponent } from '@pages/nvidia-landing-page/nvidia-wrapper.component';


const routes: Routes = [
  {
    path: 'dashboard',
    redirectTo: 'my-rain',
    pathMatch: 'full',
    resolve: [FetchCatelogueResolver]
  },
  // {
  //   path: 'home',
  //   redirectTo: '',
  //   pathMatch: 'full',
  // },
  
  {
    path: '',
    loadChildren: () => import('@pages/home/home-landing-page/home-landing-page.module').then(m => m.HomeLandingPageModule),
    data: { preload: true },
    resolve: [FetchCatelogueResolver]
  },
  {
    path: 'home',
    loadChildren: () => import('@pages/home/home.module').then(m => m.HomePageModule),
    data: { preload: true },
    resolve: [FetchCatelogueResolver]
  },
  {
    path: 'work',
    loadChildren: () => import('@pages/home/home.module').then(m => m.HomePageModule),
    data: { preload: true },
    resolve: [FetchCatelogueResolver]
  },
  {
    path: 'mobile',
    loadChildren: () => import('@pages/home/mobile-home-product/mobile-home-product/mobile-home-product.module').then(m => m.MobileHomeProductModule),
    data: { preload: true },
    resolve: [FetchCatelogueResolver]
  },
  {
    path: 'legal',
    loadChildren: () => import('@pages/legal-page/legal-page.module').then(m => m.LegalPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('@pages/change-password-page/change-password-page.module').then(m => m.ChangePasswordPageModule)
  },
  
  {
    path: 'nvidia',
    component: NvidiaWrapperComponent,
    loadChildren: () => import('@pages/nvidia-landing-page/nvidia-landing-page.module').then(m => m.NvidiaLandingPageModule),
    resolve: [FetchCatelogueResolver]
  },
  {
    path: 'data-detour',
    loadChildren: () => import('@pages/detour-page/detour-page.module').then(m => m.DetourPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('@pages/login-page/login-page.module').then(m => m.LoginPageModule),
    canActivate: [GuestGuard],
    resolve: [FetchCatelogueResolver],
    data: { preload: true }
  },
  {
    path: 'payment-details',
    loadChildren: () =>
      import('@pages/payment-details-page/payment-details-page.module').then(m => m.PaymentDetailsPagesModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'service/:id',
    loadChildren: () => import('@pages/service-page/service-page.module').then(m => m.ServicePageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'orders',
    loadChildren: () => import('@pages/order-page/order-page.module').then(m => m.OrderPageModule),
    // canActivate: [AuthenticatedGuard]
  },
  {
    path: 'support',
    loadChildren: () => import('@pages/support-page/support-page.module').then(m => m.SupportPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('@pages/user-register-page/user-register-page.module').then(m => m.UserRegisterModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'register/:routeName',
    loadChildren: () => import('@pages/user-register-page/user-register-page.module').then(m => m.UserRegisterModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'my-account',
    loadChildren: () => import('@pages/my-account/my-account.module').then(m => m.MyAccountModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'my-rain',
    loadChildren: () => import('@pages/my-rain/my-rain.module').then(m => m.MyRainModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'cart',
    loadChildren: () => import('@pages/cart-page/cart-page.module').then(m => m.CartPageModule),
    resolve: [LoadBillCycleDatesResolver]
  },
  {
    path: 'careers',
    loadChildren: () => import('@pages/careers-page/careers-page.module').then(m => m.CareersPageModule),
    data: { preload: true }
  },

  {
    path: 'about-my-rain',
    loadChildren: () => import('@pages/about-my-rain/about-my-rain.module').then(m => m.AboutMyRainModule),
    data: { preload: true }
  },
  {
    path: 'mobile-terms-and-conditions',
    loadChildren: () =>
      import('@pages/mobile-terms-and-conditions/mobile-terms-and-conditions.module').then(
        m => m.MobileTermsAndConditionsModule
      )
  },
  {
    path: 'notifications',
    loadChildren: () => import('@pages/notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'service/:serviceId/:type/migrate',
    loadChildren: () => import('@pages/migration-journey/migration-journey.module').then(m => m.MigrationJourneyModule),
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'arrears-payment-status',
    loadChildren: () =>
      import('@pages/arrears-success-failure-page/arrears-success-failure-page.module').then(
        m => m.ArrearsSuccessFailurePageModule
      )
  },
  {
    path: 'payment-status',
    loadChildren: () =>
      import('@pages/success-failure-page/success-failure-page.module').then(m => m.SuccessFailurePageModule),
  },
  {
    path: 'pay-now-payment-status',
    loadChildren: () =>
      import('@pages/pay-now-success-failure-page/pay-now-success-failure-page.module').then(
        m => m.PayNowSuccessFailurePageModule
      ),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'switch-to-prepaid',
    loadChildren: () => import('@pages/switch-to-prepaid-page/switch-to-prepaid-page.module').then(m => m.SwitchToPrepaidPageModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'port-in',
    loadChildren: () => import('./pages/service-page/rain-one-actions/port-my-number-page/port-my-number-page.module').then(m => m.PortMyNumberPageModule)
  },
  {
    path: 'cancel',
    loadChildren: () => import('./pages/service-page/plan-cancellation-page/plan-cancellation-page.module').then(m => m.PlanCancellationPageModule)
  },
  {
    path: 'cancellation-store-selector',
    loadChildren: () => import('./pages/service-page/cancellations-store-locator-page/cancellations-store-locator-page.module').then(m => m.CancellationsStoreLocatorPageModule)
  },
  {
    path: 'mnp/port-out/validate',
    loadChildren: () => import('./pages/service-page/rain-one-actions/port-out/port-out.module').then(m => m.PortOutModule)
  },
  {
    path: 'promo-terms',
    loadChildren: () => import('./pages/promo-terms/promo-terms.module').then(m => m.PromoTermsModule)
  },
  {
    path: 'giveagig',
    loadChildren: () => import('./pages/give-a-gig/give-a-gig.module').then(m => m.GiveAGigModule)
  },
  { path: 'redirect', loadChildren: () => import('./pages/redirect/redirect.module').then(m => m.RedirectModule) },
  {
    path:'4Gmobile',
    component: SettingUpNetworkComponent,
  },
  {
    path: '4Gsetup',
    loadChildren: () => import('./pages/fourg-mobile-set-up/fourg-mobile-set-up.module').then(m => m.FourGMobileSetupModule),
  },
  {
    path: 'receive-a-sim',
    loadChildren: () => import('./pages/receiver-landing-page/receiver-landing-page.module').then(m => m.ReceiverLandingModule)
  },
  {
    path: 'manage-geforce',
    loadChildren: () => import('./pages/manage-geforce/manage-gforce.module').then(m => m.ManageGeforceModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'verify-sim',
    loadChildren: () => import('./pages/verify-sim-page/verify-sim.module').then(m=>m.VerifySimModule),
  },
  {
    path: 'speed-up',
    loadChildren: () => import('./pages/speedup-page/speedup-page.module').then(m=>m.SpeedUpPageModule),
  },
  {
    path: 'skin-selector',
    loadChildren: () => import('./pages/router-skin-color-selector/router-skin-color-selector.module').then(m=>m.RouterSkinColorSelectorModule),
  },
  {
    path: 'purchase',
    loadChildren: () => import('./pages/purchase-journey/purchase-journey.module').then(m=>m.PurchaseJourneyModule),
  },  
  {
    path: 'whatsapp-faq',
    loadChildren: () => import('./pages/whatsapp-chatbot-faq/whatsapp-chatbot-faq.module').then(m => m.WhatsappChatbotFaqModule),
  },
  {
    path: 'company-details',
    loadChildren: () => import('./pages/company-details/company-details.module').then(m=>m.CompanyDetailsModule),
    canActivate: [AuthenticatedGuard]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadingStrategyService
    })
  ],
  exports: [RouterModule],
  providers: [AuthenticatedGuard, GuestGuard, SaIDGuard, PreloadingStrategyService]
})
export class AppRoutingModule {}
