import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductCategories } from '@models/productDetail';
import { IServiceDetail, IServiceGroup } from '@models/serviceDetail';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { FestiveBillingModalComponent } from '@pages/service-page/festive-billing-modal/festive-billing-modal.component';
import { CancelModalComponent } from '@pages/service-page/service-actions/cancel-modal/cancel-modal.component';
import { FiveGCancelOptinModalComponent } from '@pages/service-page/service-actions/five-g-cancel-optin-modal/five-g-cancel-optin-modal.component';
import { SuccessErrorModalComponent } from '@pages/service-page/service-actions/success-error-modal/success-error-modal.component';
import { MigrateToRainOneService } from '@services/migrate-to-rain-one.service';
import { title } from 'process';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingState } from 'src/app/core/store/state/billing.state';
import { FirebaseConfigsState } from 'src/app/core/store/state/firebase-configs.state';
import { GetPreCancellationsDate, PreCancellationsServiceId, RevokePreCancellation } from 'src/app/store/actions/PreCancellations.actions';
import { GetAllServices } from 'src/app/store/actions/services.actions';
import { PreCancellationsState } from 'src/app/store/state/PreCancellations.state';
import { ServicesState } from 'src/app/store/state/services.state';
import { RainFlags } from 'src/app/core/interfaces/rain-flags.interface';
import { take,takeUntil } from 'rxjs/operators';
import { ButtonClickedTracking } from 'src/app/store/actions/rain-analytics-tracker.actions';
import { RainAnalyticsTrackerClick } from 'src/app/store/interfaces/rain-analytics-tracker';

@Component({
  selector: 'rain-cancellation-tile',
  templateUrl: './cancellation-tile.component.html',
  styleUrls: ['./cancellation-tile.component.scss']
})
export class CancellationTileComponent implements OnInit {
  @Input() gService: IServiceGroup;
  @Select(ServicesState.GetCurrentService) selectedService$: Observable<any>
  @Select(FirebaseConfigsState.getFlags) flags$: Observable<RainFlags>;
  @ViewChild('cancelModel', { static: true }) cancelModel: TemplateRef<any>;
  private unsubscribe$: Subject<void> = new Subject<void>();
  canPreCancel = this.store.selectSnapshot(PreCancellationsState.CanPreCancel);
  legacyFourG = this.store.selectSnapshot(ServicesState.hasActiveLegacy4GService)
  legacyFiveG = this.store.selectSnapshot(ServicesState.hasActiveLegacy5GService)
  hideServiceCancellation:boolean;
  reversedRainOneCancellation$ = new BehaviorSubject(false);
  service: IServiceDetail;
  serviceID = this.route.snapshot.params['id'];

  constructor(
    private store: Store,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
    private route: ActivatedRoute,
    private migrateToRainOneService: MigrateToRainOneService
  ) { }

  ngOnInit(): void {
    this.flags$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((flags: RainFlags) => {
      this.hideServiceCancellation = flags?.hide_service_cancellation_button;
    });
    
  }


  cancel5G() {
    this.store.dispatch([new PreCancellationsServiceId(this.serviceID), new GetPreCancellationsDate(this.serviceID)])

    const rainTrackingClickOj: RainAnalyticsTrackerClick = {
      buttonId: `service-page-cancellation-modal-open`,
      buttonLabel: `service-page-cancellation-modal-open`,
      event: 'btn-click'
    }

    this.store.dispatch(new ButtonClickedTracking(rainTrackingClickOj))

    this.modalService.open(FiveGCancelOptinModalComponent, {
      centered: true,
      size: 'sm',
      windowClass: 'slideInUp d-flex'
    });

  }

  reverseRainOneCancellation() {
    if (this.canPreCancel) {
      this.store.dispatch([new RevokePreCancellation(this.serviceID), new GetAllServices()])
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }
  }
  legacyFourGCancellation() {
    CancelModalComponent.show(this.serviceID, this.modalService, "4G");
  }

  reverseFourGCancellation() {
    this.reversedRainOneCancellation$.next(true);
    this.migrateToRainOneService.reverseCancelRainOneService(this.route.snapshot.paramMap.get('id')).subscribe(() => {
      this.store.dispatch([new GetAllServices()]);
      const modalRef = this.modalService.open(SuccessErrorModalComponent, { size: <any>'confirm', windowClass: 'slideInUp', centered: true });
      const data = {
        title: "hey it's good to have you back",
        description: 'Your requested cancellation will be reversed right now.',
        buttonName: 'got it'
      }
      modalRef.componentInstance.data = data;
    });
    this.store.dispatch([new GetAllServices()]);
    setTimeout(() => {
      window.location.reload()
    }, 3000);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

