<section class="rainGo-ge-force-section">

    <div   class="rainGo-tile-work">
      <div class="rainGo-text-container">
        <div class="rainGo-text-wrapper">
          <img class="rainGo-logo-img" src="assets/images/rain-101/homepage/rainGO-logo.svg" alt="">
          <p class="buy-more-text-work"> <span class="bold-text">buy more</span><br>data & minutes on the go </p>
          <div class="section-phones-terms">
            <div class="terms-lockup">
              <a (click)="gotoTerms();" >Ts & Cs apply.</a>
            </div>
           </div>
        </div>
      </div>
      <div class="rainGo-img-container">
        <div class="rainGo-mobile-img-wrapper">
            <img  src="assets/images/rainGo/rainGO_mobile.jpg" alt="" class="rainGo-mobile-img">
    </div>
</div> 
    </div>
 </section>