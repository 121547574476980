import {Utils} from "@Utils";
import { RainAnalyticsTrackerClick } from "../interfaces/rain-analytics-tracker";

export class ButtonClickedTracking {
    static  readonly type = Utils.Helpers.Type('[Rain Analytics Tracker] Button Clicked');
    constructor(public payload: RainAnalyticsTrackerClick) { }
}
export class ButtonClickedTrackingSuccess {
    static  readonly type = Utils.Helpers.Type('[Rain Analytics Tracker] Button Clicked http Success');
}
export class ButtonClickedTrackingFailed {
    static  readonly type = Utils.Helpers.Type('[Rain Analytics Tracker] Button Clicked http Failed');
}

export class SetReferrer {
    static  readonly type = Utils.Helpers.Type('[Rain Analytics Tracker] Set referrer');
    constructor(public readonly payload: string) {}
}
export class SetGClid {
    static  readonly type = Utils.Helpers.Type('[Rain Analytics Tracker] Set gclid');
    constructor(public readonly payload: string) {}
}

export class SetSource {
    static  readonly type = Utils.Helpers.Type('[Rain Analytics Tracker] Set source');
    constructor(public readonly payload: string) {}
}

export class SetNavigationID {
    static  readonly type = Utils.Helpers.Type('[Rain Analytics Tracker] Set navigation id');
    constructor(public readonly payload: number) {}
}