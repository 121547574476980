import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { WebsiteconfigService } from 'src/app/core/services/websiteconfig.service';
import { GetActiveProducts } from 'src/app/store/actions/product.actions';
import { LoadsheddingFallbackCopy } from '../../interfaces/loadshedding-fallbacl-copy.interface';
import { ModalCopy } from '../../interfaces/modal-copy';
import { PickupPoint } from '../../interfaces/pickup-locations.interface';
import { RainFlags } from '../../interfaces/rain-flags.interface';
import {
  T2025thOfMonthDetails, T204GVerificationDetails, T20CongratsModalDetails, T20HeadsUpModalDetails, T20HomepageDetails,
  T20Payment5GDetails, T20ProductpageDetails
} from '../../interfaces/t20-promo.interface';
import { InitAdBlockerCheckerSuccess, ToggleAdBlockerModal } from '../actions/ad-blocker.actions';
import { SetRainDeliveryTme } from '../actions/auth.actions';
import * as fromFirebaseActions from '../actions/firebase.actions';
import { TopupPrices } from '../../interfaces/topup-prices.interface';
import { ProductState } from 'src/app/store/state/product.state';

export interface FirebaseStateModel {
  ad_blocker_enabled: boolean;
  show_adblocker_modal: boolean;
  productSourceUrl: string;
  deliveryTime: number;
  r1_promo_active: {
    active: boolean
  };
  pickup_locations: Array<PickupPoint>,
  nvidia_activated: boolean
  black_friday_activated: boolean
  product_type: { [name: string]: string };
  fallback_copy: LoadsheddingFallbackCopy;
  flags: RainFlags;
  giveAGigConfig: ModalCopy;
  t20_Productpage: T20ProductpageDetails;
  t20_Homepage: T20HomepageDetails;
  t20_25thOfMonthpage: T2025thOfMonthDetails;
  t20_Payment5Gpage: T20Payment5GDetails;
  t20_Congratsmodal: T20CongratsModalDetails;
  t20_HeadsUpmodal: T20HeadsUpModalDetails;
  t20_4GVerificationpage: T204GVerificationDetails;
  topUpPrices: TopupPrices;
  promos: {
    september_competition: {
      active: boolean;
      qrCode_url: string;
    },
    r1_promo: {
      active: boolean
    }
  },
  defaultSkins: DefaultSkin[],
  default101aSkins: DefaultSkin[],

}

export interface DefaultSkin {
 color: string; 
 default: boolean; 
 sku: string;
}

@State<FirebaseStateModel>({
  name: 'firebase',
  defaults: {
    ad_blocker_enabled: false,
    show_adblocker_modal: false,
    productSourceUrl: '',
    deliveryTime: null,
    r1_promo_active: {
      active: false
    },
    pickup_locations: null,
    nvidia_activated: false,
    black_friday_activated: false,
    product_type: null,
    fallback_copy: null,
    flags: null,
    giveAGigConfig: null,
    t20_Productpage: null,
    t20_Homepage: null,
    t20_25thOfMonthpage: null,
    t20_Payment5Gpage: null,
    t20_Congratsmodal: null,
    t20_HeadsUpmodal: null,
    t20_4GVerificationpage: null,
    topUpPrices: null,
    promos: {
      september_competition: {
        active: false,
        qrCode_url: null
      },
      r1_promo: {
        active: false
      }
    },
    defaultSkins: [],
    default101aSkins: [],
  }
})
@Injectable()
export class FirebaseConfigsState {
  static twentyFifthDowngrades(twentyFifthDowngrades: any): (target: import("../../../pages/service-page/service-page.component").ServicePageComponent, propertyKey: "twenty_fifth_downgrade_disabled$") => void {
    throw new Error('Method not implemented.');
  }
  static firstDowngrades(firstDowngrades: any): (target: import("../../../pages/service-page/service-page.component").ServicePageComponent, propertyKey: "first_downgrade_disabled$") => void {
    throw new Error('Method not implemented.');
  }

  @Selector()
  static getTopupPrices(state: FirebaseStateModel) {
    return state.topUpPrices
  }
  @Selector()
  static getProRataCopyDeliveryTime(state: FirebaseStateModel) {
    return state.flags.prorata_copy_del_time;
  }

  @Selector()
  static giveAgigConfig(state: FirebaseStateModel) {
    return state.giveAGigConfig;
  }

  @Selector()
  static nvidiaActive(state: FirebaseStateModel) {
    return state.nvidia_activated;
  }

  @Selector()
  static isR1PromoActive(state: FirebaseStateModel) {
    return state.r1_promo_active.active;
  }

  @Selector()
  static productsSourceUrl(state: FirebaseStateModel) {
    return state.productSourceUrl;
  }


  @Selector()
  static isAdBlokcerEnabled(state: FirebaseStateModel) {
    return state.ad_blocker_enabled;
  }


  @Selector()
  static getAdBlockerModalState(state: FirebaseStateModel) {
    return state.show_adblocker_modal;
  }

  @Selector()
  static getBlackFridayActive(state: FirebaseStateModel) {
    return state.black_friday_activated;
  }


  @Selector()
  static pickupLocations(state: FirebaseStateModel) {
    return state.pickup_locations;
  }

  static filteredPickupLocations(postalCode: string) {
    return createSelector([FirebaseConfigsState], (state: FirebaseStateModel) => {
      return state.pickup_locations.filter(l => l.address.postal_code === postalCode ? l : null)
    })
  }

  @Selector()
  static getProductType(state: FirebaseStateModel): string {
    return state.productSourceUrl;
  }
  @Selector()
  static getPromos(state: FirebaseStateModel) {
    return state.promos;
  }

  @Selector()
  static getDefaultSkins(state: FirebaseStateModel) {
    return state.defaultSkins;
  }

  @Selector()
  static getDefault101ArtSkins(state: FirebaseStateModel) {
    return state.default101aSkins;
  }

  @Selector([FirebaseConfigsState.getDefaultSkins])
  static getDefaultSetSkin(state: FirebaseStateModel, defaultSkins:DefaultSkin[]) {
    return defaultSkins.find(skin => skin?.default === true)
  }

  @Selector([FirebaseConfigsState.getDefault101ArtSkins])
  static getDefault101ArtSetSkin(state: FirebaseStateModel, default101aSkins:DefaultSkin[]) {
    return default101aSkins.find(skin => skin?.default === true);
  }


  @Selector()
  static getFallbackCopy(state: FirebaseStateModel): LoadsheddingFallbackCopy {
    return state.fallback_copy;
  }

  @Selector()
  static getFlags(state: FirebaseStateModel): RainFlags {
    return state.flags;
  }

  @Selector([FirebaseConfigsState.getFlags])
  static getCancelDisabled(state: FirebaseStateModel, flags:RainFlags) {
      return flags.cancel_disabled;
  }
  @Selector([FirebaseConfigsState.getFlags])
  static getAuDecCancelDisabled(state: FirebaseStateModel, flags:RainFlags) {
      return flags.au_dec_cancel_disabled;
  }
  @Selector([FirebaseConfigsState.getFlags])
  static getAuDecDowngradeDisabled(state: FirebaseStateModel, flags:RainFlags) {
      return flags.au_dec_downgrade_disabled;
  }
  @Selector([FirebaseConfigsState.getFlags])
  static twentyFifthDowngradesDisabled(state: FirebaseStateModel, flags:RainFlags) {
      return flags.twenty_fifth_downgrade_disabled;
  }
  @Selector([FirebaseConfigsState.getFlags])
  static twentyFifthCancellations(state: FirebaseStateModel, flags:RainFlags) {
      return flags.twenty_fifth_cancellation_disabled;
  }
  @Selector([FirebaseConfigsState.getFlags])
  static firstDowngradesDisabled(state: FirebaseStateModel, flags:RainFlags) {
      return flags.first_downgrade_disabled;
  }
  @Selector([FirebaseConfigsState.getFlags])
  static firstCancellations(state: FirebaseStateModel, flags:RainFlags) {
      return flags.first_cancellation_disabled;
  }
  @Selector()
  static getT20_Productpage(state: FirebaseStateModel): T20ProductpageDetails {
    return state.t20_Productpage;
  }

  @Selector()
  static getT20_Homepage(state: FirebaseStateModel): T20HomepageDetails {
    return state.t20_Homepage;
  }

  @Selector()
  static getT20_25thOfMonthpage(state: FirebaseStateModel): T2025thOfMonthDetails {
    return state.t20_25thOfMonthpage;
  }

  @Selector()
  static getT20_Payment5Gpage(state: FirebaseStateModel): T20Payment5GDetails {
    return state.t20_Payment5Gpage;
  }

  @Selector()
  static getT20_Congratsmodal(state: FirebaseStateModel): T20CongratsModalDetails {
    return state.t20_Congratsmodal;
  }

  @Selector()
  static getT20_HeadsUpmodal(state: FirebaseStateModel): T20HeadsUpModalDetails {
    return state.t20_HeadsUpmodal;
  }

  @Selector()
  static getT20_4GVerificationpage(state: FirebaseStateModel): T204GVerificationDetails {
    return state.t20_4GVerificationpage;
  }

  static getPickupLocationById(locationId: string) {
    return createSelector([FirebaseConfigsState], (state: any) => {

      return state?.pickup_locations?.find((l) => {
        return l.locationId === locationId
      });

    });
  }

  ngxsOnInit() {
    this.store.dispatch([new fromFirebaseActions.FetchPromoConfig(), new fromFirebaseActions.FetchPickupLocations()]);
  }

  constructor(
    private store: Store,
    private websiteConfig: WebsiteconfigService,
  ) { }


  @Action(InitAdBlockerCheckerSuccess)
  initAdBlockerCheckerSuccess(
    ctx: StateContext<FirebaseStateModel>,
    action: InitAdBlockerCheckerSuccess
  ) {
    const payload = action.payload;

    ctx.patchState({
      ad_blocker_enabled: payload
    });
  }

  @Action(ToggleAdBlockerModal)
  toggleAdBlockerModal(ctx: StateContext<FirebaseStateModel>, action: ToggleAdBlockerModal) {
    ctx.patchState({
      show_adblocker_modal: action.payload
    });
  }

  @Action(SetRainDeliveryTme)
  setRainDeliveryTme(ctx: StateContext<FirebaseStateModel>, action: SetRainDeliveryTme) {

    return ctx.patchState({
      deliveryTime: action.deliveryTime
    });
  }

  @Action(fromFirebaseActions.FetchPromoConfig)
  FetchPromoConfig(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchR1PromoConfig()
      .subscribe(
        {
          next: (res: any) => ctx.dispatch(new fromFirebaseActions.FetchPromoConfigSuccess(res)),
        }
      )
  }

  @Action(fromFirebaseActions.FetchBlackFridayPromoConfig)
  FetchBlackFridayPromoConfig(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchBlackFridayPromoConfig()
      //.pipe(takeLast(1))
      .subscribe(
        (res: any) => {
          ctx.patchState({
            black_friday_activated: res.active
          })
          this.store.dispatch(new GetActiveProducts())
        }
      )
  }

  @Action(fromFirebaseActions.FetchPromoConfigSuccess)
  FetchPromoConfigSuccess(ctx: StateContext<FirebaseStateModel>, action: fromFirebaseActions.FetchPromoConfigSuccess) {
    const { active } = action.payload;

    ctx.patchState({
      r1_promo_active: {
        active
      }
    })
  }

  @Action(fromFirebaseActions.FetchPickupLocations)
  FetchPickupLocations(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchPickupLocations()
      .subscribe(
        {
          next: (res: any) => ctx.dispatch(new fromFirebaseActions.FetchPickupLocationsSuccess(res)),
        }
      )
  }

  @Action(fromFirebaseActions.FetchNvidiaActivated)
  FetchNvidiaActivated(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchNvidiaActive()
      .subscribe(
        {
          next: (res: any) => ctx.patchState({
            nvidia_activated: res
          }),
        }
      )
  }

  @Action(fromFirebaseActions.FetchPickupLocationsSuccess)
  FetchPickupLocationsSuccess(ctx: StateContext<FirebaseStateModel>, action: fromFirebaseActions.FetchPickupLocationsSuccess) {
    const locations = action.payload.filter((l) => l.is_enable);

    ctx.patchState({
      pickup_locations: [...locations]
    })
  }

  @Action(fromFirebaseActions.SetProductsSourceUrl)
  setProductsSourceUrl(state: StateContext<FirebaseStateModel>, action: fromFirebaseActions.SetProductsSourceUrl) {
    state.patchState({
      productSourceUrl: action.payload
    });
  }

  @Action(fromFirebaseActions.FetchLoadSheddingFallbackCopy)
  FetchLoadSheddingFallbackCopy(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchLoadSheddingFallbackCopy()
      .subscribe(
        {
          next: (res: any) => ctx.patchState({
            fallback_copy: res
          }),
        }
      )
  }

  @Action(fromFirebaseActions.FetchRainFlags)
  FetchRainFlags(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchRainFlags()
      .subscribe(
        {
          next: (res: any) => ctx.patchState({
            flags: res
          }),
        }
      )
  }

  @Action(fromFirebaseActions.FetchGiveAGigPromoConfig)
  fetchGiveAGigConfig(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchGiveAGigConfig()
      .subscribe(
        {
          next: (res: any) => ctx.patchState({
            giveAGigConfig: res
          }),
        }
      )
  }

  @Action(fromFirebaseActions.FetchT20Productpage)
  FetchT20Productpage(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchT20Productpage()
      .subscribe(
        {
          next: (res) => ctx.patchState({
            t20_Productpage: res
          }),
        }
      )
  }

  @Action(fromFirebaseActions.FetchT20Homepage)
  FetchT20Homepage(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchT20Homepage()
      .subscribe(
        {
          next: (res) => ctx.patchState({
            t20_Homepage: res
          }),
        }
      )
  }

  @Action(fromFirebaseActions.FetchT2025thOfMonthpage)
  FetchT2025thOfMonthpage(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchT2025thOfMonthpage()
      .subscribe(
        {
          next: (res) => ctx.patchState({
            t20_25thOfMonthpage: res
          }),
        }
      )
  }

  @Action(fromFirebaseActions.FetchT20Payment5Gpage)
  FetchT20Payment5Gpage(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchT20Payment5Gpage()
      .subscribe(
        {
          next: (res) => ctx.patchState({
            t20_Payment5Gpage: res
          }),
        }
      )
  }

  @Action(fromFirebaseActions.FetchT20Congratsmodal)
  FetchT20Congratsmodal(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchT20Congratsmodal()
      .subscribe(
        {
          next: (res) => ctx.patchState({
            t20_Congratsmodal: res
          }),
        }
      )
  }

  @Action(fromFirebaseActions.FetchT20HeadsUpmodal)
  FetchT20HeadsUpmodal(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchT20HeadsUpmodal()
      .subscribe(
        {
          next: (res) => ctx.patchState({
            t20_HeadsUpmodal: res
          }),
        }
      )
  }

  @Action(fromFirebaseActions.FetchT204GVerificationpage)
  FetchT204GVerificationpage(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchT204GVerificationpage()
      .subscribe(
        {
          next: (res) => ctx.patchState({
            t20_4GVerificationpage: res
          }),
        }
      )
  }

  @Action(fromFirebaseActions.FetchTopupConfig)
  FetchTopupPrices(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchTopUpPrices()
      .subscribe(
        {
          next: (res) => {
            ctx.patchState({
              topUpPrices: res
            })
          },
        }
      )
  }
  @Action(fromFirebaseActions.FetchPromos)
  FetchPromos(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchPromos()
      .subscribe(
        {
          next: (res) => {
            ctx.patchState({
              promos: {
                ...res
              }
            })
          },
        }
      )
  }
  @Action(fromFirebaseActions.FetchDefaultSkins)
  FetchDefaultSkins(ctx: StateContext<FirebaseStateModel>) {

    this.websiteConfig.fetchDefaultSkin()
      .subscribe(
        {
          next: (res) => {
            const GetRain101aArtSkins = this.store.selectSnapshot(ProductState.GetRain101aArtSkins);
            
            ctx.patchState({
              defaultSkins: res?.skins,
              default101aSkins: res?.skins.filter((s) => GetRain101aArtSkins.find((as)=> as.sku === s.sku))
            })
          },
        }
      )
  }
}

