import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { CartState } from '../store/state/cart.state';
import { UIActions } from '../shared/store/actions/ui.actions';
import { Navigate } from '@ngxs/router-plugin';
import { purchaseProgressModal } from '../shared/components/purchase-progress-modal/purchase-progress-modal.component';
import { DataLayerService } from './data-layer.service';
import { EmptyCart } from '../store/actions/cart.action';

@Injectable({
  providedIn: 'root'
})
export class SwitchUIModeService {
  constructor(
    private store: Store, 
    private modalService: NgbModal,
    private dataLayerService: DataLayerService) {}

  switchUIMode(mode: string, uiMode: string): void {
    const orderType = this.store.selectSnapshot(CartState.GetOrderType);
    const handleOpenModal = () => {
      if ((orderType !== null && orderType !== undefined) && mode !== uiMode && window.location.pathname === "/cart") {
        this.openPurchaseProgressModal(mode);
        return true;
      }
      return false;
    };

    this.dataLayerService.homeLandingPageBtnClicks(mode);
  
    switch (mode) {
      case 'sme':
        if (!handleOpenModal()) {
          this.store.dispatch([
            new UIActions.SwitchUIMode({ mode: 'sme' }),
            new Navigate(['/work']),
            new EmptyCart()
          ]);
        }
        break;
  
      case 'consumer':
        if (!handleOpenModal()) {
          this.store.dispatch([
            new UIActions.SwitchUIMode({ mode: 'consumer' }),
            new Navigate(['/home']),
            new EmptyCart()
          ]);
        }
        break;
  
      case 'mobile':
        if (!handleOpenModal()) {
          this.store.dispatch([
            new UIActions.SwitchUIMode({ mode: 'mobile' }),
            new Navigate(['/mobile']),
            new EmptyCart()
          ]);
        }
        break;
  
      default:
        this.store.dispatch([
          new UIActions.SwitchUIMode({ mode: 'consumer' }),
          new Navigate(['/home']),
          new EmptyCart()
        ]);
        break;
    }
  }

  private openPurchaseProgressModal(mode: string): void {
    const modalRef = this.modalService.open(purchaseProgressModal, {
      centered: true,
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'slideInUp d-flex my-class'
    });

    modalRef.componentInstance.selectedUIMode = mode;
  }
}