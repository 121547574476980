import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FiveGCancelConfirmDetailsModalComponent } from '../five-g-cancel-confirm-details-modal/five-g-cancel-confirm-details-modal.component';
import { Router } from '@angular/router';
import { RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { ServicesState } from 'src/app/store/state/services.state';
import { PreCancellationsState } from 'src/app/store/state/PreCancellations.state';
import { GetPreCancellationsDate } from 'src/app/store/actions/PreCancellations.actions';
import { ButtonClickedTracking } from 'src/app/store/actions/rain-analytics-tracker.actions';
import { RainAnalyticsTrackerClick } from 'src/app/store/interfaces/rain-analytics-tracker';
// import { PreCancelRainOneService } from 'src/app/store/actions/services.actions';

@Component({
  selector: 'rain-five-g-cancel-optin-modal',
  templateUrl: './five-g-cancel-optin-modal.component.html',
  styleUrls: ['./five-g-cancel-optin-modal.component.scss']
})
export class FiveGCancelOptinModalComponent {

  // @Input() serviceId: string;
  @Input() type: string;
  @Input() isRainOne = false;
  public loading = true;


  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private router: Router, private store: Store) {
    setTimeout(() => {
      this.loading = false;
    }, 100);
  }


  submit() {
    this.activeModal.dismiss();

    const rainTrackingClickOj: RainAnalyticsTrackerClick = {
      buttonId: `cancel-service-call-me`,
      buttonLabel: `cancel-service-call-me`,
      event: 'btn-click'
    }

    this.store.dispatch(new ButtonClickedTracking(rainTrackingClickOj))

    this.modalService.open(FiveGCancelConfirmDetailsModalComponent, {
      size: 'confirm' as any,
      centered: true
    });
  }

  proceedToCancel() {
    this.activeModal.close();
    const service = this.store.selectSnapshot(ServicesState.GetCurrentService).id
    this.store.dispatch(new GetPreCancellationsDate(service))
    const rainTrackingClickOj: RainAnalyticsTrackerClick = {
      buttonId: `cancel-service-call-me`,
      buttonLabel: `cancel-service-call-me`,
      event: 'page-redirect'
    }

    this.store.dispatch(new ButtonClickedTracking(rainTrackingClickOj))
    this.router.navigateByUrl('/cancel')
  }
}
