import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfirmationModalComponent} from '@components/confirm-modal/confirm-modal.component';
import {SalesModalComponent} from '@components/sales-modal/sales-modal.component';
import {CACHE_4GTO5G_MIGRATION, CACHE_SUPPORT_ADDED, CACHE_SUPPORT_SIM_CANCELLATION} from '@models/constants';
import {Result, Statuses} from '@models/result';
import {IUserDetail} from '@models/userDetail';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {
    SuccessErrorModalComponent
} from '@pages/migration-journey/components/success-error-modal/success-error-modal.component';
import {
    SurveyModalComponent
} from '@pages/service-page/service-actions/cancel-modal/survey-modal/survey-modal.component';
import {AuthenticationService} from '@services/auth.service';
import {CacheService} from '@services/cache.service';
import {ConfigService} from '@services/config.service';
import {DataLayerService} from '@services/data-layer.service';
import {SubjectService} from '@services/subject.service';
import {IAddTicketRequest, SupportService} from '@services/support.service';
import {UserService} from '@services/user.service';
import {capitalize, isNil} from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../baseComponent';
import {InvestigateModalComponent} from './investigate-modal/investigate-modal.component';
import {SimSwopModalComponent} from './sim-swop-modal/sim-swop-modal.component';
import {UserComplaintModalComponent} from './user-complaint-modal/user-complaint-modal.component';
import {SpeedUpActionsComponent} from '@pages/service-page/service-actions/speed-up/speed-up-actions.component';
import {Store} from '@ngxs/store';
import {UIState} from 'src/app/shared/store/state/ui.state';

@Component({
    selector: 'app-support-button',
    templateUrl: './support-button.component.html',
    styleUrls: ['./support-button.component.scss']
})
export class SupportButtonComponent extends BaseComponent implements OnInit {

    @Input() showAsNormalText: boolean;
    @Input() isMobile: boolean;
    @Input() showSignIn: boolean = true;
    @Input() isVoiceSIM = false;
    @Input() disabled = false;
    @Input() selectedColor: string
    @Input() cssClass: string;
    @Input() gmData: string;
    @Input() fromPage: string;
    @Input() productHeading: string;
    @Input() dataText: string;
    @Input() dataId: string;
    @Input() title = 'contact support';
    @Input() cancel5g: boolean;
    @Input() subject: string;
    @Input() productPosition: string;
    @Input() email: string;
    @Input() name: string;
    @Input() msisdn: string;
    @Input() orderNo: string;
    @Input() btnClass = 'btn btn-md btn-primary';
    @Input() showForm = false;
    @Input() bypassModal = false;
    @Input() fromButton = '';
    @Input() productName = '';
    @Input() isPromo = false;
    @Input() productId: string;
    @Input() currentProductId = '';
    @Input() migrateProductId = '';
    @Input() serviceId = '';
    @Input() schedule: string;
    @Input() address: any;
    @Input() migrationType: string;

    @Output() eventCompleted = new EventEmitter<boolean>();

    _referenceId: string;

    @Input()
    set referenceId(reference: string) {
        this._referenceId = reference;

    }

    get referenceId() {
        return this._referenceId;
    }

    selectedmodal: NgbModalRef;
    complaintModalRef: NgbModalRef;
    simSwopModalRef: NgbModalRef;
    ticketRequest: IAddTicketRequest;
    siteKey: string;
    loading: boolean;
    userDetail: IUserDetail;

    constructor(
        private modalService: NgbModal,
        private authService: AuthenticationService,
        private toastr: ToastrService,
        private userService: UserService,
        private configService: ConfigService,
        private subjectService: SubjectService,
        private supportService: SupportService,
        private cacheService: CacheService,
        private dataLayerService: DataLayerService,
        private store: Store
    ) {

        super();
        this.siteKey = this.configService.SITE_KEY;

    }

    ngOnInit() {
        if (this.authService.isSignedIn) {
            this.getUserDetail();
        }

        this.gmData = this.gmData || 'support-button';

    }

    open() {
        if (this.cacheService.exists(CACHE_SUPPORT_ADDED) || this.cacheService.exists(CACHE_4GTO5G_MIGRATION)) {
            this.showAlreadyLoggedTicketModal();
            return;
        }

        this.setBaseTicket();

        if (this.bypassModal) {
            this.submitTicket();
        } else if (this.fromButton === 'simSwop' || this.fromButton === 'cancellationSurvey') {
            this.doSimSwop();

        } else if (this.fromButton === 'speed-up-wifi') {
            this.speedUpReq();

        } else if (this.fromButton === 'productCallMe') {
            this.doSalesRequest();

        } else {
            if (this.showForm === false && this.authService.isSignedIn) {
                this.doInvestigateModal();
            } else {
                this.doUserComplaint();
            }
        }
    }

    showAlreadyLoggedTicketModal() {
        const options = {size: 'confirm' as any, windowClass: 'slideInUp', centered: true};
        const modalRef = this.modalService.open(ConfirmationModalComponent, options);
        modalRef.componentInstance.data = {
            title: 'heads up',
            description: `You have already logged a ticket`,
            buttonName: 'ok',
        };
    }


    setBaseTicket() {

        let body: any = {};

        if (this.msisdn) {
            body = {
                msisdn: this.msisdn,
            };
        }

        if (this.address) {
            body['address'] = this.address?.formattedAddress
        }

        if (!this.referenceId) {
            this.referenceId = this.msisdn;
        }

        if (this.referenceId) {
            this.subject += this.referenceId;
        }

        if (this.isVoiceSIM) {
            this.subject = `SIM Swap Voice MSISDN ${this.msisdn}`
        }

        const uiMode = this.store.selectSnapshot(UIState.GetUIMode)
        const isSme = uiMode === 'sme'

        this.ticketRequest = {
            name: this.name,
            email: isSme ? `${this.email}_sme` : this.email,
            subject: this.subject,
            referenceId: this.referenceId,
            body,
            captcha: null
        };


    }

    doInvestigateModal() {

        // Track button click
        if (this.fromButton === '/my-account') {
            this.ticketRequest.body.subject = `rain billing support #${this.referenceId}`;
            this.ticketRequest.subject = `rain billing support #${this.referenceId}`;
            this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['my-account/contact-support']);
        }

        if (this.fromButton === 'networkSupport') {
            this.ticketRequest.body.subject = `rain network support #${this.referenceId}`;
            this.ticketRequest.subject = `rain network support #${this.referenceId}`;
            this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['contact/network-support']);
        }

        if (this.fromButton === 'billingSupport') {
            this.ticketRequest.subject = `rain billing support #${this.referenceId}`;
            this.ticketRequest.body.subject = `rain billing support #${this.referenceId}`;
            this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['contact/billing-support']);
        }

        const options = {size: 'survey' as any, windowClass: 'slideInUp', centered: true};
        const investigateModalRef = this.modalService.open(InvestigateModalComponent, options);
        investigateModalRef.componentInstance.fromButton = this.fromButton;
        investigateModalRef.componentInstance.submitted.subscribe((res) => {
            if (res) {
                if (res.address) {
                    this.ticketRequest.body.address = res.address;
                }

                if (this.orderNo) {
                    this.ticketRequest.body.message = 'Order No: ' + this.orderNo + '. ';
                }

                if (res.message) {

                    if (this.ticketRequest.body.message) {
                        this.ticketRequest.body.message += res.message;
                    } else {
                        this.ticketRequest.body.message = res.message;
                    }
                }

                if (res.captcha) {
                    this.ticketRequest.captcha = res.captcha;
                }
                this.selectedmodal = investigateModalRef;
                this.submitTicket();
            }
        });
    }

    speedUpReq() {
        let component: any = SpeedUpActionsComponent;
        const modalRef = this.modalService.open(component, {
            centered: true,
            size: <any>'md',
            windowClass: 'slideInUp d-flex'
        });
        modalRef.componentInstance.msisdn = this.msisdn
    }

    doUserComplaint() {
        const options = {size: 'survey' as any, windowClass: 'slideInUp', centered: true};
        this.complaintModalRef = this.modalService.open(UserComplaintModalComponent, options);
    }

    doSimSwop() {
        const modalRef = this.modalService.open(SurveyModalComponent, {size: <any>'survey', windowClass: 'slideInUp'});
        modalRef.componentInstance.serviceId = this.serviceId;
        modalRef.componentInstance.msisdn = this.msisdn;
        modalRef.componentInstance.productId = this.productId;
        modalRef.componentInstance.submitted.subscribe((res) => {
            if (res) {
                modalRef.close();
                this.simSwap();
            }
        })
    }

    simSwap() {
        const options = {size: 'survey' as any, windowClass: 'slideInUp', centered: true};
        const simSwopModalRef = this.modalService.open(SimSwopModalComponent, options);
        simSwopModalRef.componentInstance.data = this.ticketRequest;
        simSwopModalRef.componentInstance.submitted.subscribe((res) => {

            if (res) {
                if (res.address) {
                    this.ticketRequest.body.address = res.address;
                }

                if (res.message) {
                    this.ticketRequest.body.message = res.message;
                }

                this.selectedmodal = simSwopModalRef;
                this.ticketRequest.body.subject = `rain sim support #${this.referenceId}`;

                if (this.isVoiceSIM) {
                    this.ticketRequest.body.subject = `SIM Swap Voice MSISDN ${this.msisdn}`
                }
                this.submitTicket();
            }

        });
    }

    doSalesRequest() {
        const options = {size: 'sm' as any, windowClass: 'slideInUp', centered: true};
        const salesModalRef = this.modalService.open(SalesModalComponent, options);
        salesModalRef.componentInstance.productName = this.productName;
        salesModalRef.componentInstance.subject = this.subject;
        salesModalRef.componentInstance.isPromo = this.isPromo;
        salesModalRef.componentInstance.showSignIn = this.showSignIn;
        salesModalRef.componentInstance.gmData = `${this.gmData} - submit form`;
        salesModalRef.componentInstance.fromPage = this.fromPage;
        salesModalRef.componentInstance.productHeading = this.productHeading;
    }

    getUserDetail() {

        this.loading = true;
        this.userService.get()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result: Result<IUserDetail>) => {

                this.loading = false;

                if (result.status === Statuses.Success) {
                    this.userDetail = result.value;
                    this.name = capitalize(this.userDetail?.firstName) + ' ' + capitalize(this.userDetail?.lastName);
                    this.email = this.userDetail?.email;
                } else {
                    this.loading = false;
                    setTimeout(() => this.toastr.error('An error occurred while processing request. Please try again', null));
                }
            });
    }

    showThankYouModal() {
        const options = {size: 'confirm' as any, windowClass: 'slideInUp', centered: true};
        const modalRef = this.modalService.open(ConfirmationModalComponent, options);
        modalRef.componentInstance.data = {
            title: 'Thank you, ' + this.userDetail.firstName,
            description: `<p>We have received your ticket.</p>
      <p>An agent will get back to you as soon as possible.</p>`,
            buttonName: 'got it'

        };
        this.cacheService.set(CACHE_SUPPORT_ADDED, 'set', 86400000);
        this.supportService.setLocaStorage();
    }

    other4GTo5GMigrationSuccessModal(message) {
        const modalRef = this.modalService.open(SuccessErrorModalComponent, {
            size: <any>'confirm',
            windowClass: 'slideInUp',
            centered: true
        });
        modalRef.componentInstance.title = 'success';
        modalRef.componentInstance.description = message;
        modalRef.componentInstance.serviceId = this.serviceId;
        modalRef.componentInstance.btnName = 'got it';
    }

    openRequested() {
        const options = {size: 'confirm' as any, windowClass: 'slideInUp', centered: true};
        const modalRef = this.modalService.open(ConfirmationModalComponent, options);
        modalRef.componentInstance.data = {
            title: 'success',
            description: `
      <p>Your SIM swap request has been received. An agent will be in contact soon.</p>
      `,
            buttonName: 'got it'
        };

        modalRef.componentInstance.accepted.subscribe((result) => {
            if (result) {
                modalRef.close();
            }
        });

        this.cacheService.set(CACHE_SUPPORT_ADDED, 'set');
        this.supportService.setLocaStorage();
    }

    recordScheduledAction() {
        let type = 'Migration';
        if (this.subject.includes('cancellation')) {
            type = 'Cancellation';

            // Track event
            this.dataLayerService.modalView(this.dataLayerService.modalTitleMap['cancel-service']);
        }
        const action = {
            customerId: this.userService.getUserId(),
            transactionType: type,
            currentProduct: this.currentProductId,
            newProduct: this.migrateProductId || null,
            serviceId: this.serviceId,
            requestType: 'Request',
            source: 'Web'
        }
        this.userService.recordScheduleActions(action).subscribe((res) => {
            console.log('recorded migration');
        }, e => {
            console.log('recorded migration error');
        });
    }

    submitTicket() {
        this.loading = true;

        this.supportService.addTicket(this.ticketRequest)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result: any) => {

                this.eventCompleted.emit(true);

                this.loading = false;
                if (isNil(result) === false) {
                    if (!result?.ticket) {
                        setTimeout(() => this.toastr.error('An error occurred while submitting. Please try again', null));
                        return;

                    } else {
                        if (this.fromButton === '/my-account') {
                            this.dataLayerService.contactSupport();
                        }

                        this.loading = false;
                        if (this.fromButton === 'cancellationSurvey') {
                            this.subjectService.simSwapResponse(true);
                            this.openRequested();
                        } else if (this.fromButton === 'simSwop') {
                            this.openRequested();
                        } else if (this.fromButton === 'networkSupport' || this.fromButton === 'billingSupport' || this.fromButton === 'myOrderContactSupport') {
                            this.showThankYouModal();
                        } else {

                            if (this.migrationType === 'other4GTo5GMigration') {
                                // OTHER 4G TO 5G SUCCESS
                                this.cacheService.set(CACHE_4GTO5G_MIGRATION, this.serviceId, 86400000);
                                this.supportService.setLocaStorage();
                                this.subjectService.other4GTO5GMigrationResponse(true);
                                const message = 'Your request has been successfully logged. An agent will be in touch with you as soon as possible.';
                                this.other4GTo5GMigrationSuccessModal(message);
                            } else {
                                if (this.fromButton === 'cancel_5g_no_thanks' || this.fromButton === 'cancel_5g_submit') {
                                    this.cacheService.set(CACHE_SUPPORT_SIM_CANCELLATION, this.serviceId, 86400000);
                                    this.supportService.setLocaStorage();
                                    this.subjectService.cancel5GResponse(true);
                                } else {
                                    this.cacheService.set(CACHE_SUPPORT_ADDED, 'set', 86400000);
                                    this.supportService.setLocaStorage();
                                    this.subjectService.fivegTofiveMigrationnResponse(this.schedule);
                                }

                                // ADD RECORDING OF SCHEDULED ACTION;
                                if (this.currentProductId) {
                                    this.recordScheduledAction();
                                }
                            }

                        }

                    }
                    this.selectedmodal.close();
                }
            });
    }
}
